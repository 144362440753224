// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AuthProvider } from '../routes/AuthContext';
import PrivateRoute from '../routes/PrivateRoute';
import AdminRoute from '../routes/AdminRoute';
import About from '../pages/Admin/About';
import AdminDashboard from '../pages/Superadmin/Home';
import AdminBooking from '../pages/Superadmin/Home1';
import AdminPayment from '../pages/Superadmin/Payment'
import Login from '../pages/Superadmin/Login';
import NavbarTop from './Navbar';
import Footer from './Footer';
// import Check from '../components/booking/AllBooking'
import GHome from '../pages/user/Home'

import Home from '../pages/user/Home'

const App = () => {
  return (
    <AuthProvider>
    <Router>
      <NavbarTop/>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/home" component={GHome} />
        <Route path="/" exact component={Home} />
        {/* <PrivateRoute path="/" exact component={AdminDashboard} /> */}
        <PrivateRoute path="/roomCleaningAdmin" component={About} />
        <AdminRoute path="/admin/home/room" component={AdminDashboard} />
        <AdminRoute path="/admin/home/booking" component={AdminBooking} />
        <AdminRoute path="/admin/home/payment" component={AdminPayment} />
      </Switch>
      <Footer/>
    </Router>
  </AuthProvider>
  );
};

export default App;
