import React, { createContext, useContext, Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import apiRequest from "../routes/ApiService";

const AuthContext = createContext();

const getUserFromLocalStorage = () => {
  const storedUserData = localStorage.getItem("user");
  return storedUserData ? JSON.parse(storedUserData) : null;
};

export class AuthProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token") || null,
      user: getUserFromLocalStorage() || null,
      loading: true,
    };
  }

  login = (newToken, userData) => {
    localStorage.setItem("token", newToken);
    localStorage.setItem("user", JSON.stringify(userData));
    this.setState({ token: newToken, user: userData });
  };

  logout = () => {
    this.setState({ token: null, user: null });
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  };

  isAuthenticated = async (isAuthenticated) => {
    const { token, user } = this.state;

    if (!token || !user) {
      return false;
    }

    try {
      const response = await apiRequest("/api/verify1", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        // Ensure that the user role or any other validation criteria are met
        if (data.user && data.user.role === user.role) {
          this.setState({
            user: data.user,
            token: data.token,
          });
          return true;
        } else {
          toast.error("User data mismatch. Logging out.");
          this.logout(); // Logout if user data mismatch
          return false;
        }
      } else {
        console.error("Token verification failed.");
        this.logout(); // Logout if token verification fails
        return false;
      }
    } 
    catch (error) {
      console.error("Error during token verification:", error);
      this.logout(); 
      return false;
    }
  };

  componentDidMount() {
    this.isAuthenticated(true).finally(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <>
        <AuthContext.Provider
          value={{
            token: this.state.token,
            user: this.state.user,
            login: this.login,
            logout: this.logout,
            isAuthenticated: this.isAuthenticated,
            loading: this.state.loading,
          }}
        >
          {this.props.children}
        </AuthContext.Provider>
        <ToastContainer />
      </>
    );
  }
}

export const useAuth = () => {
  return useContext(AuthContext);
};
