import React from "react";
import { ToastContainer, toast } from "react-toastify";
class Step5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reSendEmail: "",
    };
  }
  handleClosePopup = () => {
    localStorage.removeItem('Bookinguser') 
    localStorage.removeItem('Bookingtoken')
    this.setState({
      closePopup: true,
    });
    this.props.handleClosePopup(this.state.closePopup);
    
  };
  reSendEmail = () => {
    toast.success("Email Send Successfully...");
  };
  render() {
    return (
      <div className="popup">
        <ToastContainer></ToastContainer>
        <h6 className="text-center">Payment Details</h6>
        <p>Order ID : {this.props.orderId}</p>
        <p>Payment ID : {this.props.paymentId}</p>
        <h6 className="text-center">Booking Details</h6>
        <p>Booking ID : {this.props.bookingData.bookingId}</p>
        <p>Total Amount : {this.props.bookingData.bookingData.TotalAmount}</p>
        <p>
          Room Numbers:{" "}
          {this.props.bookingData.selectedRoom.map((item, index) => (
            <span key={index}>{item}</span>
          ))}
        </p>
        <div className="d-flex justify-content-between">
          <button className="btn btn-primary" onClick={this.reSendEmail}>
            Re-Send Email
          </button>
          <button className="btn btn-danger" onClick={this.handleClosePopup}>
            Close
          </button>
        </div>
      </div>
    );
  }
}
export default Step5;
