import React from "react";
import {
  Carousel,
  Card,
  Navbar,
  Nav,
  NavDropdown,
  Button,
  Accordion,
} from "react-bootstrap";
import {Link} from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ChandraBhaga from "../../images/ChandraBhaga.jpg";
import kalyanMandap from "../../images/kalyanMandap.jpg";

import FirstSlider from "../../images/slider-01.jpg";
import SecondSlider from "../../images/slider-03.jpg";

import AllStep from "../../components/user/booking/AllStep";

import GetRoom from "../../components/user/gettingAll";
import Facility from "../../components/user/fac";
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      showPop: false,
    };
  }
  handleMenuClick = () => {
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));
  };
  handleMouseEnterShowPop = () => {
    this.setState({
      showPop: true,
    });
  };
  handleMouseLeavePop = () => {
    this.setState({
      showPop: false,
    });
  };
  render() {
    const { showMenu, showPop } = this.state;
    const loggedIn = false;
    return (
      <div>
        <div className={showPop === true ? "overlay" : ""}></div>
        {showMenu && (
          <Navbar bg="light" expand="lg" sticky="top">
            <Navbar.Brand href="#">Your Brand</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className={showMenu ? "show" : ""}
            >
              <Nav className="mr-auto">
                <Link to="/login">
                <Nav.Link href="#">Home</Nav.Link>
                </Link>
                <Nav.Link href="#">About</Nav.Link>
                <Nav.Link href="#">Services</Nav.Link>
                {/* Add more Nav.Link components as needed */}
              </Nav>
              <Nav>
                {loggedIn ? (
                  <NavDropdown title="User Profile" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#">Profile</NavDropdown.Item>
                    <NavDropdown.Item href="#">Settings</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#">Logout</NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <Button variant="primary" href="#">
                    Login
                  </Button>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        )}
        <div>
          <Button
            className={"menu-btn hideMenu"}
            onClick={this.handleMenuClick}
          >
            =
          </Button>
          <Carousel
            fade
            pause
            slide={true}
            interval={5000}
            touch={true}
            indicators={false}
          >
            <Carousel.Item>
              <img
                className="d-block w-100 cimg"
                src={FirstSlider}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 cimg "
                src={SecondSlider}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 cimg"
                src={ChandraBhaga}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100 cimg"
                src={kalyanMandap}
                alt="Second slide"
              />
            </Carousel.Item>
            {/* Add more Carousel.Item components for additional slides */}
          </Carousel>
        </div>
        <div
          className="ranjitkolhal"
          onMouseEnter={this.handleMouseEnterShowPop}
          onMouseLeave={this.handleMouseLeavePop}
        >
          <AllStep closeOverlay={this.handleMouseLeavePop} />
        </div>

        {/* Room Types Section */}
        <div className="startbody">
          <section className="roomcard">
            {/* <h6>Room Type</h6> */}
            <div className="row room-row">
              {/* Single Room Card */}
              <div className="col-md-4">
                <Card className="card">
                  {" "}
                  {/* Add 'card' class here */}
                  <Card.Img
                    variant="top"
                    src="https://www.iskconbangalore.org/wp-content/uploads/2019/11/Guest-House-iskcon8-1200x800.jpg"
                  />
                  <Card.Body>
                    <Card.Title>AC Room</Card.Title>
                    <Card.Text>
                      Experience comfort and tranquility in our air-conditioned
                      (AC) rooms at ISKCON Pandhaprur Guest House. Our rooms
                      offer a serene retreat amidst the bustling surroundings.
                      Enjoy modern amenities, serene ambiance, and a peaceful
                      environment conducive to spiritual reflection and
                      relaxation.
                    </Card.Text>
                    <Button variant="primary">Read More</Button>
                  </Card.Body>
                </Card>
              </div>

              {/* Family Room Card */}

              <div className="col-md-4">
                <Card className="card">
                  {" "}
                  {/* Add 'card' class here */}
                  <Card.Img
                    variant="top"
                    src="https://www.iskconbangalore.org/wp-content/uploads/2019/11/Guest-House-iskcon6-1200x800.jpg"
                  />
                  <Card.Body>
                    <Card.Title>Non-AC Room</Card.Title>
                    <Card.Text>
                      Discover simplicity and spiritual serenity in our
                      non-air-conditioned (non-AC) rooms at ISKCON Pandhaprur
                      Guest House. Embrace a humble abode with basic amenities,
                      fostering an environment for contemplation and devotion.
                    </Card.Text>
                    <Button variant="primary">Read More</Button>
                  </Card.Body>
                </Card>
              </div>

              {/* Presidential Room Card */}
              <div className="col-md-4">
                <Card className="card">
                  {" "}
                  {/* Add 'card' class here */}
                  <Card.Img
                    variant="top"
                    src="https://3.imimg.com/data3/CN/HS/MY-3538801/var-m_0-0e-0e2-6662-79802-2.png"
                  />
                  <Card.Body>
                    <Card.Title>Dormitory</Card.Title>
                    <Card.Text>
                      Embrace community living in our dormitory at ISKCON
                      Pandharpur Guest House. Perfect for budget-conscious
                      travelers, our dormitory provides a shared space for
                      spiritual seekers. Enjoy a simple yet communal
                      environment, fostering connections and shared experiences.
                    </Card.Text>
                    <Button variant="primary">Read More</Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </section>
          <section className="room-counter">
            <div className="ourroom">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path
                  fill="#62d0df"
                  fillOpacity="1"
                  d="M0,96L80,85.3C160,75,320,53,480,42.7C640,32,800,32,960,37.3C1120,43,1280,53,1360,58.7L1440,64L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
                ></path>
              </svg>
            </div>
            <div>
              <h2 className="testuserranjit">Live Status Of Room's</h2>
              <div className="otherdata">
                <GetRoom />
              </div>
            </div>
          </section>
          <section>
            <Facility />
          </section>
          <section className="about-guest container-fluid">
            <h2>About Of Guest House</h2>
            <p className="mb-5 p-3">
              100+ Guest rooms will be built to facilitate visitors and devotees
              who wish to stay close to the temple and visit it frequently. They
              can stay at ISKCON Pandharpur guest house, which is located nearby
              the temple premises. There are both AC and Non-AC rooms at the
              guesthouse. Only pure vegetarian meals are provided at this place.
              Interested pilgrims and devotees are advised to book their rooms
              in advance to avoid any last-minute rush.
              <br />
              Bhuvaikunth project have many accommodations available in Sri
              Pandharpur Dham that will suite the needs of anybody. As of now we
              have facilities to accommodate over 1000 pilgrims. We offer online
              booking as well.
              <br />
              100+ Guest rooms will be build to facilitate visitors and devotees
              who wish to stay close to the temple and visit it frequently. They
              can stay at ISKCON Pandharpur guest house, which is located nearby
              the temple premises. There are both AC and Non-AC rooms at the
              guesthouse. Only pure vegetarian meals are provided at this place.
              Interested pilgrims and devotees are advised to book their rooms
              in advance to avoid any last-minute rush.
              <br />
              The guest house is strategically located from a number of
              prominent holy places some of which are within walking distance.
              The property is also surrounded by lush green flora and fauna with
              a majestic view of the campus. The guest house provides the
              perfect getaway for business travelers and families looking to get
              away from the hustle and bustle of city life. Its scenic beauty is
              too photogenic and unique.
            </p>
          </section>
          <section className="container-fluid faq mb-5 p-3">
            <h3>Frequently Ask Questions</h3>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>How can I make a reservation?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>What is the cancellation policy?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Can I modify my reservation?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Is breakfast included in the room rate?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Can I request additional amenities?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </section>
          <br />
          <br />
          <section className="container-fluid">
            
            <h2 className="facility-title">Reviews</h2>
            <div className="ifcontainer">
            <iframe className="responsive-iframe" src='https://widgets.sociablekit.com/google-reviews/iframe/25407173' frameborder='0'></iframe>            
            </div>
            </section>
        </div>
      </div>
    );
  }
}

export default Home;
