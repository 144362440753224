// RoomReport.jsx
import React, { Component } from "react";

class RoomReport extends Component {
  render() {
    const { rooms } = this.props;

    const totalRooms = rooms.length;
    const freeRooms = rooms.filter(
      (room) => room.status.toLowerCase() === "available"
    ).length;
    const busyRooms = rooms.filter(
      (room) => room.status.toLowerCase() === "busy"
    ).length;
    const cleanRooms = rooms.filter(
      (room) => room.status.toLowerCase() === "notclean"
    ).length;

    const guestHouseCounts = rooms.reduce((acc, room) => {
      acc[room.guestHouse] = (acc[room.guestHouse] || 0) + 1;
      return acc;
    }, {});

    const acCounts = rooms.reduce((acc, room) => {
      acc[room.AC] = (acc[room.AC] || 0) + 1;
      return acc;
    }, {});

    return (
      <>
        <h5 className="reporTitle">Room Report</h5>
        <div className="roomReport">
          
          <div>
            <h3>Guest House-wise Room Counts:</h3>
            <ul>
              {Object.entries(guestHouseCounts).map(([guestHouse, count]) => (
                <li key={guestHouse}>
                  {guestHouse}: {count} rooms
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3>Room Status</h3>
            <p>Total Rooms: {totalRooms}</p>
            <div className="d-flex w-100">
              <div>
                <label>Available</label>
                <div className="roomStatusBox Available">{freeRooms}</div>
              </div>
              <div>
                <label>Busy</label>
                <div className="roomStatusBox Busy">{busyRooms}</div>
              </div>
              <div>
                <label>NotClean</label>
                <div className="roomStatusBox NotClean">{cleanRooms}</div>
              </div>
            </div>
          </div>
          <div>
            <h3>AC-wise Room Counts:</h3>
            <ul>
              {Object.entries(acCounts).map(([ac, count]) => (
                <li key={ac}>
                  {ac}: {count} rooms
                </li>
              ))}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

export default RoomReport;
