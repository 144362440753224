// Login.js
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../routes/AuthContext";
import apiRequest from "../../routes/ApiService";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FloatingLabel,
} from "react-bootstrap";

const Login = ({ onLoginSuccess }) => {
  const { isAuthenticated, user, login } = useAuth();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const history = useHistory();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await apiRequest("/api/admin/auth/login", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: credentials.username,
          password: credentials.password,
        }),
      });
      const data = await response.json();

      if (response.ok) {
        const { token, user } = data;
        login(token, user);
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));

        if (user.role === "superAdmin") {
          history.push("/admin/home/room");
        } else if (user.role === "admin") {
          history.push("/roomCleaningAdmin");
        } else if (user.role === "user") {
          history.push("/");
        } else {
          toast.error("Error during login");
        }

        // Call the parent component's callback on successful login
        onLoginSuccess(data);
      } else {
        toast.error("Error during login:", data.error);
      }
    } catch (error) {
      toast.error("Error during login:", error);
    }
  };

  useEffect(() => {
    if (isAuthenticated && user && user.role === "superAdmin") {
      // You might want to add more specific conditions here based on roles
      history.push("/admin/home/room");
    } else if (isAuthenticated && user && user.role === "admin") {
      history.push("/roomCleaningAdmin");
    } else if (isAuthenticated && user && user.role === "user") {
      history.push("/");
    }
  }, [isAuthenticated, user, history]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  return (
    <div className="loginadmin">
      <ToastContainer></ToastContainer>
      <div className="kkk">
        <FloatingLabel controlId="username" label="username">
          <Form.Control
            type="email"
            name="username"
            onChange={handleChange}
            placeholder="Adult"
          />
        </FloatingLabel>
        <FloatingLabel controlId="password" label="password">
          <Form.Control
            type="password"
            name="password"
            onChange={handleChange}
            placeholder="Adult"
          />
        </FloatingLabel>
        {/* <input type="text" name="username" onChange={handleChange} /> */}
        {/* <input type="password" name="password" onChange={handleChange} /> */}
        <button onClick={handleLogin} className="btn btn-primary">
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;
