import React from "react";

import {
    FaWifi,
    FaUtensils,
    FaParking,
    FaToiletPaper,
  } from "react-icons/fa";
  import { MdOutlineFoodBank } from "react-icons/md";
  import { TbDeviceCctv } from "react-icons/tb";
  
  import { RiWaterFlashFill } from "react-icons/ri";
  import { GiWaterBottle } from "react-icons/gi";
  import { FaPersonWalkingLuggage, FaMattressPillow } from "react-icons/fa6";
  import { FcCancel } from "react-icons/fc";
  import Slider from "react-slick";
  
class Facility extends React.Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 700,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: false,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  initialSlide: 2,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ],
          };
        return (
            <div>
            <section className="facility-section">
            <h2 className="facility-title">Facilities</h2>
            <Slider {...settings}>
                <div className="facility-item">
                  <FaWifi className="facility-icon" />
                  <h3>Free Wi-Fi</h3>
                  <p>
                    Stay connected with high-speed internet throughout the
                    hotel. Only in temple premises
                  </p>
                </div>
                <div className="facility-item">
                  <FaUtensils className="facility-icon" />
                  <h3>Restaurant</h3>
                  <p>Enjoy delicious meals served by our skilled chefs.</p>
                </div>
                <div className="facility-item">
                  <FaParking className="facility-icon" />
                  <h3>Parking</h3>
                  <p>Convenient on-site parking available for all guests.</p>
                </div>
                <div className="facility-item">
                  <FaPersonWalkingLuggage className="facility-icon" />
                  <h3>24/7 CheckIn - CheckOut</h3>
                  <p>Flexibility to check-in and checkout anytime.</p>
                </div>
                <div className="facility-item">
                  <RiWaterFlashFill className="facility-icon" />
                  <h3>Hot Water</h3>
                  <p>24/7 hot water supply available in all rooms.</p>
                </div>
                <div className="facility-item">
                  <GiWaterBottle className="facility-icon" />
                  <h3>Drinking Water</h3>
                  <p>Complimentary drinking water provided in each room.</p>
                </div>
                <div className="facility-item">
                  <FcCancel className="facility-icon" />
                  <h3>Cancellation Available</h3>
                  <p>Flexible cancellation policy for your convenience.</p>
                </div>
                <div className="facility-item">
                  <MdOutlineFoodBank className="facility-icon" />
                  <h3>Food Facility</h3>
                  <p>Delicious food options available for your cravings.</p>
                </div>
                <div className="facility-item">
                  <TbDeviceCctv className="facility-icon" />
                  <h3>CCTV</h3>
                  <p>24/7 surveillance for your safety and security.</p>
                </div>
                <div className="facility-item">
                  <FaToiletPaper className="facility-icon" />
                  <h3>Attached Toilet</h3>
                  <p>
                    Comfortable rooms with attached toilets for your
                    convenience.
                  </p>
                </div>
                <div className="facility-item">
                  <FaMattressPillow className="facility-icon" />
                  <h3>Extra Mattress Available</h3>
                  <p>Additional mattress provided upon request.</p>
                </div>
            </Slider>
          </section>
            </div>
        );
    }
}

export default Facility;