import React, { Component } from "react";
import menu from "./icon/menu.png";
import room from './icon/room.png';
import booking from './icon/booking.png';
import addBooking from './icon/addbooking.png';
import { Link } from "react-router-dom";
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowOpen: true,
    };
  }

  openClose = () => {
    this.setState((prevState) => ({
      windowOpen: !prevState.windowOpen,
    }));
  };

  render() {
    const { windowOpen } = this.state;

    return (
      <nav
        className="navbar-menu"
        style={{ width: windowOpen === false ? 200 : 60 }}
      >
        <div className="burger" onClick={this.openClose}>
          <img src={menu} alt="burger" />
        </div>
        <ul className="navbar__list">
          <div className="navbar__li-box">
            <Link to="/admin/home/room">

            <img
              src={room}
              alt=""
              style={{ paddingLeft: windowOpen === false ? 27 : 17 }}
            />
           
            <li
              className="navbar__li"
              style={{
                display: windowOpen === false ? "inline-block" : "none",
              }}
            >
              Room
            </li>
            </Link>
          </div>
          <div className="navbar__li-box">
            <Link to="/admin/home/booking">
            <img
              src={booking}
              alt=""
              style={{ paddingLeft: windowOpen === false ? 27 : 17 }}
            />
            <li
              className="navbar__li"
              style={{
                display: windowOpen === false ? "inline-block" : "none",
              }}
            >
              Booking
            </li>
            </Link>
          </div>
          <div className="navbar__li-box">
            <Link to="/admin/home/payment">
            <img
              src={addBooking}
              alt=""
              style={{ paddingLeft: windowOpen === false ? 27 : 17 }}
            />
            <li
              className="navbar__li"
              style={{
                display: windowOpen === false ? "inline-block" : "none",
              }}
            >
              Add Booking
            </li>
            </Link>
          </div>
        </ul>
      </nav>
    );
  }
}

export default Navbar;
