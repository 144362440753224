import React from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import apiRequest from '../../routes/ApiService'
class AllRoom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allRoom: null,
      totalRooms: null,
      availableRooms: null,
      loading: true,
      error: null,
      currentDateTime: new Date().toLocaleString(), // Initial current date and time
      isVisible: false, // Flag to track component visibility
    };
  }

  componentDidMount() {
    // Fetch room data
    apiRequest("/api/getallroom")
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          allRoom: data,
          totalRooms: data.length,
          availableRooms: data.filter((room) => room.status.toLowerCase() === "available")
            .length,
          loading: false,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        this.setState({
          error: "Error fetching data",
          loading: false,
        });
      });

    // Update current date and time every second
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted to prevent memory leaks
    clearInterval(this.timerID);
  }

  tick() {
    // Update current date and time in the state
    this.setState({
      currentDateTime: new Date().toLocaleString(),
    });
  }

  onChangeVisibility = (isVisible) => {
    if (isVisible) {
      // If the component becomes visible, set the isVisible flag to true
      this.setState({ isVisible: true });
    }
  };

  render() {
    const {
      totalRooms,
      availableRooms,
      loading,
      error,
      currentDateTime,
      // isVisible,
    } = this.state;
    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 3000,
    //     responsive: [
    //       {
    //         breakpoint: 1024,
    //         settings: {
    //           slidesToShow: 3,
    //           slidesToScroll: 1,
    //           infinite: true,
    //           dots: true,
    //         },
    //       },
    //       {
    //         breakpoint: 768,
    //         settings: {
    //           slidesToShow: 2,
    //           slidesToScroll: 1,
    //           initialSlide: 2,
    //         },
    //       },
    //       {
    //         breakpoint: 480,
    //         settings: {
    //           slidesToShow: 1,
    //           slidesToScroll: 1,
    //         },
    //       },
    //     ],
    //   };
    return (
      <div className="getallRoom">
        <h6 className="currentDateRoom">
          <span>{currentDateTime}</span>
        </h6>
        <VisibilitySensor onChange={this.onChangeVisibility} partialVisibility>
          {({ isVisible }) => (
            <ul className="roomcouterall">
              <li>
                Total Rooms <br />
                <span>
                  {isVisible && (
                    <CountUp duration={3} start={0} end={totalRooms + 27} />
                  )}
                </span>
              </li>
              <li>
                Available Rooms <br />
                <span>
                  {isVisible && (
                    <CountUp duration={3} start={0} end={availableRooms} />
                  )}
                </span>
              </li>
              <li>
                Busy Rooms <br />
                <span>
                  {isVisible && (
                    <CountUp
                      duration={2}
                      start={0}
                      end={totalRooms + 27 - availableRooms}
                    />
                  )}
                </span>
              </li>
              <li>
                Total Guest House
                <br />
                <span>
                  {isVisible && <CountUp duration={2} start={0} end={2} />}
                </span>
              </li>
              {/* Add more details about each room here if needed */}
            </ul>
          )}
        </VisibilitySensor>
        {loading ? <p>Loading...</p> : error ? <p>{error}</p> : null}
      </div>
    );
  }
}

export default AllRoom;
