import ReactDOM from "react-dom/client";
import React from "react";
import Route from "./routes/Routing";
import "./style/styles.css";
import { AuthProvider } from "./routes/AuthContext";
import "react-toastify/dist/ReactToastify.css";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <Route />
  </AuthProvider>
);
