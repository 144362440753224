import logo from './img/logo.png';
import apiRequest from '../../routes/ApiService';
export const handleRazorpay = async (orderId, amount1, callback) => {
  const options = {
    amount : parseInt(amount1 * 100),
    key: "rzp_live_fDTn21QUNwBNpA",
    currency: "INR",
    name: "ISKCON Pandharpur",
    description: "Room Booking ISKCON Pandharpur",
    image: {logo},
    order_id: orderId,
    handler: async function (response) {
      const body = {
        ...response,
      }
      try{
        const verifyPayment = await apiRequest('/api/verify/payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        })
        const verifed = await verifyPayment.json();
        console.log(verifed);
        if (callback && typeof callback === "function") {
          callback(verifed);
        }
      }
      catch(err){
        console.log(err);
      }
    },    
    prefill: {
      name: "Gaurav Kumar",
      email: "gaurav.kumar@example.com",
      contact: "9000090000",
    },
    notes: {
      address: "ISKCON Pandharpur Guest House",
    },
    theme: {
      color: "#ff6501",
    },
  
  };
  const rzp = new window.Razorpay(options);
  rzp.open();
};
