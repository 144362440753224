import React from "react";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer, toast } from "react-toastify";


import apiRequest from "../../../routes/ApiService";

class AddRoom extends React.Component {
  constructor() {
    super();
    this.state = {
      roomNumber: "",
      rate: "",
      AC: "",
      status: "",
      guestHouse: "",
    };
  }
  fetchRooms() {
    apiRequest("/api/rooms")
      .then((response) => response.json())
      .then((data) => this.setState({ rooms: data }))
      .catch((error) => console.error("Error fetching rooms:", error));
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();

    apiRequest("/api/rooms", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        roomNumber: this.state.roomNumber,
        rate: this.state.rate,
        AC: this.state.AC,
        status: this.state.status,
        guestHouse: this.state.guestHouse,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 400) {
          return res.json().then((data) => {
            toast.error(data.error || "An error occurred. Please try again.");
            throw new Error(data.error || "Unknown error");
          });
        } else {
          throw new Error("Unexpected server response");
        }
      })
      .then((data) => {
        toast.success("Room added successfully!");
      })
      .catch((error) => {
        if (error.message !== "Unknown error" && error.status !== 400) {
          // Log only if it's not the "Unknown error" case or 400 status
        }
      });
  };

  render() {
    return (
      <div>
        <ToastContainer />
        <form onSubmit={this.handleSubmit} className="addRoomForm">
          <div className="form-group">
            <label htmlFor="roomNumber">Room Number:</label>
            <input
              type="number"
              name="roomNumber"
              id="roomNumber"
              onChange={this.handleChange}
              className="form-control"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="rate">Rate:</label>
            <input
              type="number"
              name="rate"
              id="rate"
              onChange={this.handleChange}
              className="form-control"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="AC">Air Conditioner:</label>
            <select
              name="AC"
              id="AC"
              onChange={this.handleChange}
              className="form-control"
              required
            >
              <option value="">Select</option>
              <option value="AC">AC</option>
              <option value="Non-AC">Non-AC</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="status">Status:</label>
            <select
              name="status"
              id="status"
              onChange={this.handleChange}
              className="form-control"
              required
            >
              <option value="">Select</option>
              <option value="Available">Available</option>
              <option value="Busy">Busy</option>
              <option value="NotClean">Not Clean</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="guestHouse">Guest House:</label>
            <select
              name="guestHouse"
              id="guestHouse"
              onChange={this.handleChange}
              className="form-control"
              required
            >
              <option value="">Select</option>
              <option value="ChandraBhaga">ChandraBhaga</option>
              <option value="KalyanMandap">KalyanMandap</option>
            </select>
          </div>

          <button type="submit" className="submitBtn">
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default AddRoom;
