import React from "react";
import AllBooking from "../../components/admin/booking/AllBooking";
import "./custom.css";
// import image from './icon/image.png'
class Home1 extends React.Component {
  componentDidMount() {
    window.addEventListener("beforeunload", this.clearLocalStorage);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.clearLocalStorage);
  }

  clearLocalStorage = () => {
    localStorage.removeItem("Bookinguser");
    localStorage.removeItem("Bookingtoken");
  };

  render() {
    return (
      <div className="container123">
        <div className="content-123 w-100">
          <AllBooking />
        </div>
      </div>
    );
  }
}

export default Home1;
