import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Form, FloatingLabel } from "react-bootstrap";
import apiRequest from "../../../routes/ApiService";
import Step3 from "./duplicate/AllStep";
import checkin from './img/checkin.png';
import checkout from './img/checkout.png';
import Step4 from "./duplicate/ourStep";
class AllBooking extends React.Component {
  constructor(props) {
    super();
    this.state = {
      bookings: [],
      searchTerm: "",
      filterStatus: "all",
      userData: "",
      bookingData: "",
      showUserData: false,
      showBookingData: false,
      showStep3: false,
      startDate: this.getCurrentDateTimeString(), // Set the default value to current date
      endDate: "",
      todaysCheckIn : [],
      todaysCheckOut : [],
      assignroom : false,
      assignbookingId : "" 
    };
  }
  // Function to filter today's check-in and check-out data
  filterTodayBookings = () => {
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in "YYYY-MM-DD" format
  
    const { bookings } = this.state;
  
    const todaysCheckIn = bookings.filter(booking => {
      // Ensure booking.checkIn exists and is not undefined
      if (!booking.bookingData || !booking.bookingData.checkIn) return false;
      // Extract the date part from the booking checkIn datetime
      const bookingCheckInDate = booking.bookingData.checkIn.split('T')[0]; // Extract YYYY-MM-DD
      // Compare the booking checkIn date with the current date
      return bookingCheckInDate === currentDate;
    });
  
    const todaysCheckOut = bookings.filter(booking => {
      // Ensure booking.checkOut exists and is not undefined
      if (!booking.bookingData || !booking.bookingData.checkOut) return false;
      // Extract the date part from the booking checkOut datetime
      const bookingCheckOutDate = booking.bookingData.checkOut.split('T')[0]; // Extract YYYY-MM-DD
      // Compare the booking checkOut date with the current date
      return bookingCheckOutDate === currentDate;
    });
  
    // console.log("Today's CheckIn Bookings:", todaysCheckIn);
    // console.log("Today's CheckOut Bookings:", todaysCheckOut);
  
    // Set todaysCheckIn and todaysCheckOut bookings in state
    this.setState({ todaysCheckIn, todaysCheckOut });
  };
  

  getCurrentDateTimeString() {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${(now.getMonth() + 1).toString().padStart(2, "0")}`;
    const day = `${now.getDate().toString().padStart(2, "0")}`;
    const hours = `${now.getHours().toString().padStart(2, "0")}`;
    const minutes = `${now.getMinutes().toString().padStart(2, "0")}`;

    // Format the date in a way that datetime-local input accepts (YYYY-MM-DDTHH:mm)
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  handleMouseLeavePop = () => {
    this.setState({
      showPop: false,
      showStep3: false,
    });
  };
  async componentDidMount() {
    await this.fetchAllBookings(); // Wait for bookings to be fetched
    setTimeout(() => {
      this.filterTodayBookings(); // Filter today's bookings

    }, 2000);
  }
  
  fetchAllBookings = () => {
    apiRequest("/api/booking", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          bookings: data,
          filteredBookings: data,
        });
      });
  };
  handleSearchChange = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  handleStatusFilterChange = (event) => {
    this.setState({ filterStatus: event.target.value });
  };

  formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    return dateObject.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone: "UTC",
    });
  };

  assignroom = (bookingId) => {
      this.setState ({
        assignbookingId : bookingId, 
        assignroom : true
      })
  }
  closeassignroom = () => {
    this.setState({
      assignroom : false
    })
  }
  handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name.startsWith("userData.")) {
      // Update user data fields
      const field = name.split(".")[1];
      this.setState((prevState) => ({
        userData: {
          ...prevState.userData,
          [field]: value,
        },
      }));
    }
  };
  handleEdit = async () => {
    const { name, email, address, city, state, pincode, phonenumber } =
      this.state.userData;

    // Check if all required fields are filled
    if (
      !name ||
      !email ||
      !address ||
      !city ||
      !state ||
      !pincode ||
      !phonenumber
    ) {
      toast.error(
        "All fields are required. Please fill in all the required information."
      );
      return;
    }

    // Basic email validation
    if (!/\S+@\S+\.\S+/.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    // Pincode validation (6 digits)
    const pincodeRegex = /^\d{6}$/;
    if (!pincodeRegex.test(pincode)) {
      toast.error("Please enter a valid 6-digit pincode.");
      return;
    }

    // Phone number validation (10 digits)
    const phonenumberRegex = /^\d{10}$/;
    if (!phonenumberRegex.test(phonenumber)) {
      toast.error("Please enter a valid 10-digit phone number.");
      return;
    }
    try {
      await fetch(`/api/register/${this.state.userData._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: this.state.userData.name,
          email: this.state.userData.email.toLowerCase(),
          address: this.state.userData.address,
          city: this.state.userData.city,
          state: this.state.userData.state,
          pincode: this.state.userData.pincode,
          phonenumber: this.state.userData.phonenumber,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          toast.success("User Updated successfully");
          this.setState({
            showUserData: false,
          });
        })
        .catch((error) => {
          // console.log(error);
        });
    } catch (err) {
      // console.log(err);
    }
  };
  handleDelete = async (_id, bookingId) => {
    const userConfirmed = window.confirm(
      `Are you sure you want to delete ${bookingId} this booking? `
    );

    if (!userConfirmed) {
      // User clicked "No"
      return;
    }

    apiRequest(`/api/booking/${bookingId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          bookingData: data,
        });
        toast.success(`Booking Deleted successfully ${bookingId}`);
        this.fetchAllBookings();
        // console.log(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getBookingDetails = (bookingId) => {
    // console.log(bookingId);
    apiRequest(`/api/booking/${bookingId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          bookingData: data,
          showBookingData: true,
        });
        // console.log(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  getUserDetails = (userId) => {
    apiRequest("/api/admin/auth/getUserDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: userId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState((pre) => ({
          userData: data.user,
          showUserData: !pre.showUserData,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleClosePopup = (e) => {
    e.preventDefault();
    this.setState({
      showUserData: false,
      showBookingData: false,
    });
  };
  addBooking = () => {
    localStorage.removeItem('Bookingtoken')
    localStorage.removeItem('Bookinguser')
    this.setState((prevState) => ({
      showStep3: !prevState.showStep3,
    }));
  };
  clearfilter = () => {
    this.setState({
      searchTerm: "",
      filterStatus: "all",
      endDate: "",
      startDate: "",
    });
    this.fetchAllBookings();
  };
  render() {
    const {
      bookings,
      filterStatus,
      searchTerm,
      userData,
      bookingData,
      startDate,
      endDate,
    } = this.state;

    const options = {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    const filteredBookings = bookings.filter((item) => {
      const matchSearch = item.bookingId.toString().includes(searchTerm);
      const matchStatus =
        filterStatus === "all" || item.status === filterStatus;

      // Format startDate and endDate according to options
      const formattedStartDate = startDate
        ? new Date(startDate).toLocaleString("en-US", options)
        : null;
      const formattedEndDate = endDate
        ? new Date(endDate).toLocaleString("en-US", options)
        : null;

      // Format check-in and check-out dates according to options
      const formattedCheckInDate = new Date(
        item.bookingData.checkIn
      ).toLocaleString("en-US", options);
      const formattedCheckOutDate = new Date(
        item.bookingData.checkOut
      ).toLocaleString("en-US", options);

      // Perform date comparison
      const matchDate =
        (!startDate || formattedCheckInDate >= formattedStartDate) &&
        (!endDate || formattedCheckOutDate <= formattedEndDate);

      return matchSearch && matchStatus && matchDate;
    });

    return (
      <div>
        <br />
        <h2 className="mb-5 text-center">Booking Management</h2>
        <ToastContainer />
        <div className="d-flex justify-content">
          {/* <button onClick={this.filterTodayBookings}>Get Booking </button> */}
          <div  className="bookingTable AllRoom w-100">
         
            <h5 className="text-center mb-4">Today CheckIn  <img src={checkin} alt="" /></h5>
            <table>
            <thead>
              <tr>
                <th>UserId</th>
                <th>Booking ID</th>
                <th>CheckIn</th>
                <th>CheckOut</th>
                <th>Room Number</th>
                <th>Room Type</th>
              </tr>
            </thead>
            <tbody>
              {this.state.todaysCheckIn.map((item) => (
                <tr key={item._id}>
                  <td className="useridadmin" onClick={() => this.getUserDetails(item.userId)}>
                    {item.userId}
                  </td>
                  <td>{item.bookingId}</td>
                  <td>
                    {new Date(item.bookingData.checkIn).toLocaleString(
                      "en-US",
                      options
                    )}
                  </td>

                  <td>
                    {new Date(item.bookingData.checkOut).toLocaleString(
                      "en-US",
                      options
                    )}
                  </td>
                  <td>
                    {item.selectedRoom.map((room) => (
                      <div key={room.id}>{room}</div>
                    ))}
                  </td>
                  <td>{item.bookingData.AC}</td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          <div className="bookingTable AllRoom w-100">
          <h5 className="text-center mb-4">Today CheckOut  <img src={checkout} alt="" /></h5>
          <table>
            <thead>
              <tr>
                <th>UserId</th>
                <th>Booking ID</th>
                <th>CheckIn</th>
                <th>CheckOut</th>
                <th>Room Number</th>
                <th>Room Type</th>
              </tr>
            </thead>
            <tbody>
              {this.state.todaysCheckOut.map((item) => (
                <tr key={item._id}>
                  <td className="useridadmin" onClick={() => this.getUserDetails(item.userId)}>
                    {item.userId}
                  </td>
                  <td>{item.bookingId}</td>
                  <td>
                    {new Date(item.bookingData.checkIn).toLocaleString(
                      "en-US",
                      options
                    )}
                  </td>

                  <td>
                    {new Date(item.bookingData.checkOut).toLocaleString(
                      "en-US",
                      options
                    )}
                  </td>
                  <td>
                    {item.selectedRoom.map((room) => (
                      <div key={room.id}>{room}</div>
                    ))}
                  </td>
                  <td>{item.bookingData.AC}</td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="roomfilter">
          <label>
            Booking ID
            <input
              type="text"
              value={searchTerm}
              onChange={this.handleSearchChange}
            />
          </label>
          <label>
            Filter by Status:
            <br />
            <select
              value={filterStatus}
              onChange={this.handleStatusFilterChange}
            >
              <option value="all">All</option>
              <option value="ac">AC</option>
              <option value="non-ac">Non AC</option>
            </select>
          </label>
          <label htmlFor="">
            Start Date 
            <br />
            <input
              type="datetime-local"
              value={startDate}
              onChange={(e) => this.setState({ startDate: e.target.value })}
            />
          </label>

          <label htmlFor="">
          End Date 
            <br />
            <input
              type="datetime-local"
              value={endDate}
              onChange={(e) => this.setState({ endDate: e.target.value })}
            />
          </label>
          <label onClick={this.clearfilter} className="btn btn-primary h-25  mt-auto">Clear Filter</label>
        
        </div>

        <div>
          {this.state.showStep3 === true && (
            <Step3
              closeOverlay={this.handleMouseLeavePop}
              onProceed={this.handleCheckInOutProceed}
            />
          )}
        </div>
        <div className="bookingTable AllRoom bookingTable1">
        <div>
          <span onClick={this.addBooking} className="newBooking">
            Add New Booking
          </span>
        </div>
          <table>
            <thead>
              <tr>
                <th>UserId</th>
                <th>Booking ID</th>
                <th>CheckIn</th>
                <th>CheckOut</th>
                <th>Room Number</th>
                <th>Room Type</th>
                <th>Assign Room</th>
                <th>Edit</th>
                <th>Cancel</th>
              </tr>
            </thead>
            <tbody>
              {filteredBookings.map((item) => (
                <tr key={item._id}>
                  <td className="useridadmin" onClick={() => this.getUserDetails(item.userId)}>
                    {userData.name}
                  </td>
                  <td>{item.bookingId}</td>
                  <td>
                    {new Date(item.bookingData.checkIn).toLocaleString(
                      "en-US",
                      options
                    )}
                  </td>

                  <td>
                    {new Date(item.bookingData.checkOut).toLocaleString(
                      "en-US",
                      options
                    )}
                  </td>
                  <td>
                    {item.selectedRoom.map((room) => (
                      <div key={room.id}>{room}</div>
                    ))}
                  </td>
                  <td>{item.bookingData.AC}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.assignroom(item.bookingId)}
                    >
                      Assign Room
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-warning"
                      onClick={() => this.getBookingDetails(item.bookingId)}
                    >
                      Edit
                    </button>
                  </td>
                  <td>
                    <button
                      className="delbtn"
                      onClick={() =>
                        this.handleDelete(item._id, item.bookingId)
                      }
                    >
                      Cancel
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {this.state.showBookingData && (
          <div className="popup">
            <button onClick={this.handleClosePopup} className="popupCloseBtn">
              X
            </button>
            {this.state.showBookingData && (
              <Form className="bookingForm">
                <h3 className="text-center">Booking Data</h3>
                <Form.Group className="havingtest">
                  <div>
                    <FloatingLabel
                      controlId="floatingCheckIn"
                      label="Booking ID"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="BookingId"
                        value={bookingData.bookingId}
                        onChange={this.handleInputChange}
                        placeholder="Check In"
                        readOnly
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingCheckIn"
                      label="Room Number"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="BookingId"
                        value={bookingData.selectedRoom
                          .map((room) => room)
                          .join(", ")}
                        onChange={this.handleInputChange}
                        placeholder="Check In"
                        readOnly
                      />
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingCheckIn"
                      label="Check In"
                      className="mb-3"
                    >
                      <Form.Control
                        type="datetime-local"
                        name="checkIn"
                        value={bookingData.bookingData.checkIn}
                        onChange={this.handleInputChange}
                        placeholder="Check In"
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingCheckOut"
                      label="Check Out"
                      className="mb-3"
                    >
                      <Form.Control
                        type="datetime-local"
                        name="checkOut"
                        value={bookingData.bookingData.checkOut}
                        onChange={this.handleInputChange}
                        placeholder="Check Out"
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingAdult"
                      label="Adult"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="Adult"
                        value={bookingData.bookingData.Adult}
                        onChange={this.handleInputChange}
                        placeholder="Adult"
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingChild"
                      label="Child"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="Child"
                        value={bookingData.bookingData.Child}
                        onChange={this.handleInputChange}
                        placeholder="Child"
                      />
                    </FloatingLabel>
                  </div>
                  <div>
                    <FloatingLabel
                      controlId="floatingRoomType"
                      label="Room Type"
                      className="mb-3"
                    >
                      <Form.Control
                        as="select"
                        name="RoomType"
                        value={bookingData.bookingData.RoomType}
                        onChange={this.handleInputChange}
                        placeholder="Room Type"
                      >
                        <option value="ac">AC</option>
                        <option value="non-ac">Non-AC</option>
                      </Form.Control>
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingTotalRoom"
                      label="Room Count"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="TotalRoom"
                        value={bookingData.bookingData.TotalRoom}
                        onChange={this.handleInputChange}
                        placeholder="Room Count"
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingTotalMattress"
                      label="Mattress Count"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="TotalMattress"
                        value={bookingData.bookingData.TotalMattress}
                        onChange={this.handleInputChange}
                        placeholder="Mattress Count"
                        readOnly
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingTotalAmount"
                      label="Total Amount"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="TotalAmount"
                        value={bookingData.bookingData.TotalAmount}
                        onChange={this.handleInputChange}
                        placeholder="Total Amount"
                        readOnly
                      />
                    </FloatingLabel>
                  </div>
                  <Form.Text
                    className="text-center m-auto"
                    id="passwordHelpBlock"
                    muted
                  >
                    Total Time:{" "}
                    {Math.floor(this.state.TotalTime / (1000 * 60 * 60))} hours{" "}
                    {Math.floor((this.state.TotalTime / (1000 * 60)) % 60)}{" "}
                    minutes || Total Day : {this.state.TotalDay}
                    <br />
                  </Form.Text>
                </Form.Group>
              </Form>
            )}
          </div>
        )}

        {this.state.assignroom && (
          <div >
            <Step4 id= {this.state.assignbookingId} closeassignroom={this.closeassignroom}/>
          </div>
          )}

        <div>
          {this.state.showUserData && (
            <div className="popup">
              <button onClick={this.handleClosePopup} className="popupCloseBtn">
                X
              </button>
              <Form className="bookingForm">
                <Form.Group>
                  <h3 className="text-center">User Data</h3>
                  <FloatingLabel
                    controlId="floatingName"
                    label="Name"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="userData.name"
                      id=""
                      value={userData.name}
                      onChange={this.handleInputChange}
                      placeholder="Name"
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingEmail"
                    label="Email"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="userData.email"
                      id=""
                      value={userData.email}
                      onChange={this.handleInputChange}
                      placeholder="Email"
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingPhoneNumber"
                    label="Phone Number"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="userData.phonenumber"
                      id=""
                      value={userData.phonenumber}
                      onChange={this.handleInputChange}
                      placeholder="Phone Number"
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingState"
                    label="State"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="userData.state"
                      id=""
                      value={userData.state}
                      onChange={this.handleInputChange}
                      placeholder="State"
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingCity"
                    label="City"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="userData.city"
                      id=""
                      value={userData.city}
                      onChange={this.handleInputChange}
                      placeholder="City"
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingAddress"
                    label="Address"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="userData.address"
                      id=""
                      value={userData.address}
                      onChange={this.handleInputChange}
                      placeholder="Address"
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingPinCode"
                    label="Pin Code"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="userData.pincode"
                      id=""
                      value={userData.pincode}
                      onChange={this.handleInputChange}
                      placeholder="Pin Code"
                    />
                  </FloatingLabel>
                </Form.Group>
              </Form>
              <button className="btn btn-primary" onClick={this.handleEdit}>
                Update
              </button>
            </div>
          )}
        </div>
    
      </div>
    );
  }
}

export default AllBooking;
