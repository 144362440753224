import React from "react";
import apiRequest from "../../routes/ApiService";

class Payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bookings: []
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        apiRequest('/api/booking')
            .then(res => res.json())
            .then(data => {
                this.setState({ bookings: data });
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    calculateTotalPaymentsForType(type) {
        const { bookings } = this.state;
        const filteredBookings = bookings.filter(booking => {
            return booking.paymentData && booking.paymentData.paymentType.toLowerCase() === type.toLowerCase();
        });
        return filteredBookings.reduce((total, booking) => total + booking.bookingData.TotalAmount, 0);
    }

    calculateTotalPaymentsForDate(date) {
        const { bookings } = this.state;
        const startOfWeek = new Date(date);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()); // Set start of week to Sunday

        const endOfWeek = new Date(date);
        endOfWeek.setDate(endOfWeek.getDate() - endOfWeek.getDay() + 6); // Set end of week to Saturday

        const filteredBookings = bookings.filter(booking => {
            const paymentDate = new Date(booking.paymentData.paymentDate);
            return paymentDate >= startOfWeek && paymentDate <= endOfWeek;
        });

        return filteredBookings.reduce((total, booking) => total + booking.bookingData.TotalAmount, 0);
    }

    render() {
        const { bookings } = this.state;
        const today = new Date();
        const totalToday = this.calculateTotalPaymentsForDate(today);

        // Calculate start of current week (Sunday)
        const startOfWeek = new Date(today);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());

        const totalWeek = this.calculateTotalPaymentsForDate(startOfWeek);
        const totalCash = this.calculateTotalPaymentsForType('Cash');
        const totalOnline = this.calculateTotalPaymentsForType('Online');

        return (
            <div className="container">
                <h2 className="text-center mt-5">Payment Management</h2>
                <div className="payment1">
                    <div className="payment">
                        <h3>Today's Total Payment: <span> ₹ {totalToday}</span></h3>
                        {/* <h3>This Week's Total Payment: ₹ {totalWeek}</h3> */}
                    </div>
                    <div className="firstdiv">

                    <div className="cashpayment">
                        <h3>Total Cash Payments: <span> ₹ {totalCash}</span></h3>
                    </div>
                    <div className="onlinepayment">
                        <h3>Total Online Payments: <span>₹ {totalOnline}</span></h3>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Payment;
