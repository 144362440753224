import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FloatingLabel,
} from "react-bootstrap";

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkIn: this.props.checkIn,
      checkOut: this.props.checkOut,
      Adult: this.props.Adult,
      Child: this.props.Child,
      RoomType: this.props.AC,
      TotalRoom: "",
      TotalMattress: "",
      TotalAmount: "",
      TotalDay: "",
      TotalTime: "",
      userData: {
        _id: "",
        name: "",
        email: "",
        address: "",
        pincode: "",
        city: "",
        state: "",
        phonenumber: "",
      },
      checkUserData: false,
      checkBookingData: true,
      closePopup: true,
      isAdmin : false
    };
  }
  handleClosePopup = () => {
    this.setState({
      closePopup: true,
    });
    this.props.handleClosePopup(this.state.closePopup);
  };
  handleShowSlide = () => {
    this.setState({
      checkUserData: true,
      checkBookingData: false,
    });
  };
  handleShowSlidePre = () => {
    this.setState({
      checkUserData: false,
      checkBookingData: true,
    });
  };
  calculateTotalRoomsAndMattresses = () => {
    const { Adult } = this.state;
    const adultsPerRoom = 3;
    const bedsPerRoom = 2;

    const totalRooms = Math.ceil(parseInt(Adult, 10) / adultsPerRoom);
    const totalAdults = parseInt(Adult, 10);
    const totalBeds = totalRooms * bedsPerRoom;

    const additionalMattresses = Math.max(0, totalAdults - totalBeds);

    this.setState(
      {
        TotalRoom: totalRooms.toString(),
        TotalMattress: additionalMattresses.toString(),
      },
      () => {
        this.calculatePrice();
      }
    );
  };

  calculatePrice = () => {
    const { TotalRoom, TotalMattress, RoomType, checkIn, checkOut } =
      this.state;
    const roomPrice = RoomType === "ac" ? 1300 : 800;
    const mattressPrice = 200;
    const start = new Date(checkIn);
    const end = new Date(checkOut);

    // Calculate the difference in time in milliseconds
    const differenceInTime = end.getTime() - start.getTime();

    // Calculate the number of 24-hour periods
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    // Calculate the check-in hour
    const checkInHour = start.getHours();

    // Apply an extra charge if check-in is between 12 AM and 4 AM
    const nightCheckInCharge = checkInHour >= 0 && checkInHour < 4 ? 500 : 0;
    // console.log("roomPrice:", roomPrice);
    // console.log("TotalRoom:", TotalRoom);
    // console.log("mattressPrice:", mattressPrice);
    // console.log("TotalMattress:", TotalMattress);
    // console.log("differenceInDays:", differenceInDays);
    // console.log("nightCheckInCharge:", nightCheckInCharge);

    // Calculate the total amount
    const totalAmount =
      (roomPrice * parseInt(TotalRoom, 10) +
        mattressPrice * parseInt(TotalMattress, 10)) *
        differenceInDays +
      nightCheckInCharge * parseInt(TotalRoom, 10);
    // Update the state with calculated values

    this.setState({
      TotalDay: differenceInDays,
      TotalTime: differenceInTime,
      TotalAmount: totalAmount,
    });
  };

  updateMattressCount = (totalRooms) => {
    const { Adult } = this.state;
    // console.log("total adult" + Adult);

    const totalBeds = totalRooms * 2;
    // console.log("total Bed" + totalBeds);
    const totalAdults = parseInt(Adult, 10);
    // console.log("total Adult" + totalAdults);
    const additionalMattresses = Math.max(0, totalAdults - totalBeds);
    // console.log("total additonal mat" + additionalMattresses);

    this.setState(
      {
        TotalMattress: additionalMattresses.toString(),
      },
      () => {
        this.calculatePrice();
      }
    );
  };
  handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "checkOut" && value < this.state.checkIn) {
      toast.error("Check Out date cannot be before Check In date");
      return;
    }

    if (name.startsWith("userData.")) {
      // Update user data fields
      const field = name.split(".")[1];
      this.setState((prevState) => ({
        userData: {
          ...prevState.userData,
          [field]: value,
        },
      }));
    } else if (name === "TotalRoom") {
      const { Adult } = this.state;
      const totalRooms = Math.ceil(parseInt(Adult, 10) / 3);
      const totalAdults = parseInt(Adult, 10);
      const passRooms = event.target.value;
      if (value > totalAdults) {
        alert("Room count cannot be more than total number of adults");
        return;
      }

      if (value < totalRooms) {
        alert("Room count cannot be less than total number of adults");
        return;
      }

      this.setState(
        {
          [name]: value,
        },
        () => {
          this.updateMattressCount(passRooms); // Call updateMattressCount() after updating room count
        }
      );
    } else {
      this.setState(
        {
          [name]: value,
        },
        () => {
          this.calculateTotalRoomsAndMattresses();
          this.calculatePrice();
        }
      );
    }
  };

  componentDidMount = () => {
    this.calculateTotalRoomsAndMattresses();
    const userDataString = localStorage.getItem("user") ;
    if (userDataString) {
      const userDataObject = JSON.parse(userDataString);
      // console.log(userDataObject);
      this.setState({
        userData: userDataObject,
      });
    }
  };

  handleEdit = async () => {
    const { name, email, address, city, state, pincode, phonenumber } =
      this.state.userData;

    // Check if all required fields are filled
    if (
      !name ||
      !email ||
      !address ||
      !city ||
      !state ||
      !pincode ||
      !phonenumber
    ) {
      toast.error(
        "All fields are required. Please fill in all the required information."
      );
      return;
    }

    // Basic email validation
    if (!/\S+@\S+\.\S+/.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    // Pincode validation (6 digits)
    const pincodeRegex = /^\d{6}$/;
    if (!pincodeRegex.test(pincode)) {
      toast.error("Please enter a valid 6-digit pincode.");
      return;
    }

    // Phone number validation (10 digits)
    const phonenumberRegex = /^\d{10}$/;
    if (!phonenumberRegex.test(phonenumber)) {
      toast.error("Please enter a valid 10-digit phone number.");
      return;
    }
    try {
      await fetch(`/api/register/${this.state.userData._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: this.state.userData.name,
          email: this.state.userData.email.toLowerCase(),
          address: this.state.userData.address,
          city: this.state.userData.city,
          state: this.state.userData.state,
          pincode: this.state.userData.pincode,
          phonenumber: this.state.userData.phonenumber,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          // toast.success("User and Booking Details saved successfully");
          this.props.onProceed(
            data.message,
            this.state.TotalAmount,
            this.state.TotalRoom,
            this.state.TotalMattress
          );
          // localStorage.setItem("user", JSON.stringify(data.user));
          localStorage.setItem(
            "bookingData",
            JSON.stringify({
              checkIn: this.state.checkIn,
              checkOut: this.state.checkOut,
              Adult: this.state.Adult,
              Child: this.state.Child,
              AC: this.state.RoomType,
              TotalRoom: this.state.TotalRoom,
              TotalMattress: this.state.TotalMattress,
              TotalAmount: this.state.TotalAmount,
              RoomType: this.state.RoomType,
              userData: this.state.userData,
            })
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const userDataToUse = this.state.isAdmin
      ? JSON.parse(localStorage.getItem("userDataTemp")) || JSON.parse(localStorage.getItem("user"))
      : JSON.parse(localStorage.getItem("user"))

    const {
      checkIn,
      checkOut,
      Adult,
      Child,
      TotalRoom,
      TotalMattress,
      TotalAmount,
      RoomType,
    } = this.state;
    
    const userData = userDataToUse;
    const { checkUserData, checkBookingData } = this.state;
    return (
      <div>
        <ToastContainer></ToastContainer>
        <Container className="popup">
          <button onClick={this.handleClosePopup} className="popupCloseBtn">
            X
          </button>
          <Row className="justify-content-center">
            {checkBookingData && (
              <Col>
                <Form className="bookingForm">
                  <h3 className="text-center">Booking Data</h3>
                  <Form.Group className="havingtest">
                    <div>
                      <FloatingLabel
                        controlId="floatingCheckIn"
                        label="Check In"
                        className="mb-3"
                      >
                        <Form.Control
                          type="datetime-local"
                          name="checkIn"
                          value={checkIn}
                          onChange={this.handleInputChange}
                          placeholder="Check In"
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingCheckOut"
                        label="Check Out"
                        className="mb-3"
                      >
                        <Form.Control
                          type="datetime-local"
                          name="checkOut"
                          value={checkOut}
                          onChange={this.handleInputChange}
                          placeholder="Check Out"
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingAdult"
                        label="Adult"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="Adult"
                          value={Adult}
                          onChange={this.handleInputChange}
                          placeholder="Adult"
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingChild"
                        label="Child"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="Child"
                          value={Child}
                          onChange={this.handleInputChange}
                          placeholder="Child"
                        />
                      </FloatingLabel>
                    </div>
                    <div>
                      <FloatingLabel
                        controlId="floatingRoomType"
                        label="Room Type"
                        className="mb-3"
                      >
                        <Form.Control
                          as="select"
                          name="RoomType"
                          value={RoomType}
                          onChange={this.handleInputChange}
                          placeholder="Room Type"
                        >
                          <option value="ac">AC</option>
                          <option value="non-ac">Non-AC</option>
                        </Form.Control>
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingTotalRoom"
                        label="Room Count"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="TotalRoom"
                          value={TotalRoom}
                          onChange={this.handleInputChange}
                          placeholder="Room Count"
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingTotalMattress"
                        label="Mattress Count"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="TotalMattress"
                          value={TotalMattress}
                          onChange={this.handleInputChange}
                          placeholder="Mattress Count"
                          readOnly
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="floatingTotalAmount"
                        label="Total Amount"
                        className="mb-3"
                      >
                        <Form.Control
                          type="text"
                          name="TotalAmount"
                          value={TotalAmount}
                          onChange={this.handleInputChange}
                          placeholder="Total Amount"
                          readOnly
                        />
                      </FloatingLabel>
                    </div>
                    <Form.Text
                      className="text-center m-auto"
                      id="passwordHelpBlock"
                      muted
                    >
                      Total Time:{" "}
                      {Math.floor(this.state.TotalTime / (1000 * 60 * 60))}{" "}
                      hours{" "}
                      {Math.floor((this.state.TotalTime / (1000 * 60)) % 60)}{" "}
                      minutes || Total Day : {this.state.TotalDay}
                      <br />
                    </Form.Text>
                  </Form.Group>
                </Form>
              </Col>
            )}
          </Row>
          <Row>
            {checkUserData && (
              <Col width={100}>
                <Form className="bookingForm">
                  <Form.Group>
                    <h3 className="text-center">User Data</h3>
                    <FloatingLabel
                      controlId="floatingName"
                      label="Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="userData.name"
                        id=""
                        value={userData.name}
                        onChange={this.handleInputChange}
                        placeholder="Name"
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingEmail"
                      label="Email"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="userData.email"
                        id=""
                        value={userData.email}
                        onChange={this.handleInputChange}
                        placeholder="Email"
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingPhoneNumber"
                      label="Phone Number"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="userData.phonenumber"
                        id=""
                        value={userData.phonenumber}
                        onChange={this.handleInputChange}
                        placeholder="Phone Number"
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingState"
                      label="State"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="userData.state"
                        id=""
                        value={userData.state}
                        onChange={this.handleInputChange}
                        placeholder="State"
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingCity"
                      label="City"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="userData.city"
                        id=""
                        value={userData.city}
                        onChange={this.handleInputChange}
                        placeholder="City"
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingAddress"
                      label="Address"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="userData.address"
                        id=""
                        value={userData.address}
                        onChange={this.handleInputChange}
                        placeholder="Address"
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingPinCode"
                      label="Pin Code"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        name="userData.pincode"
                        id=""
                        value={userData.pincode}
                        onChange={this.handleInputChange}
                        placeholder="Pin Code"
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Form>
              </Col>
            )}

            <div className="d-flex mt-3">
              {checkUserData && (
                <Col>
                  <Button
                    className="d-block m-auto"
                    variant="primary"
                    onClick={this.handleShowSlidePre}
                  >
                    Previous
                  </Button>
                </Col>
              )}
              {checkUserData && (
                <Col>
                  <Button
                    className="d-block m-auto"
                    variant="primary"
                    onClick={this.handleEdit}
                  >
                    Save & Continue
                  </Button>
                </Col>
              )}

              {checkBookingData && (
                <Col>
                  <Button
                    className="d-block m-auto"
                    variant="primary"
                    onClick={this.handleShowSlide}
                  >
                    Correct
                  </Button>
                </Col>
              )}
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Step3;
