import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import RoomReport from "../../components/admin/room/RoomCount";

import apiRequest from "../../routes/ApiService";
class RoomList extends Component {
  constructor() {
    super();
    this.state = {
      rooms: [],
      searchTerm: "",
      filterAC: "all",
      filterGuestHouse: "all",
      filterStatus: "notClean",
      myPop: false,
      editData: {},
      roomNumber: "",
      rate: "",
      AC: "",
      status: "",
      guestHouse: "",
      addNewRoom: {
        roomNumber: "",
        rate: "",
        AC: "",
        status: "",
        guestHouse: "",
      },
      showComponentAdd: false,
      selectedRoom: null,
      myToken: null,
    };
  }

  componentDidMount() {
    this.fetchRooms();
  }

  fetchRooms() {
    apiRequest("/api/rooms", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => this.setState({ rooms: data }))
      .catch((error) => console.error("Error fetching rooms:", error));
  }

  handleEdit = (roomNumber) => {
    apiRequest(`/api/rooms/${roomNumber}`)
      .then((response) => response.json())
      .then((data) => {
        // console.log("Current Room Data:", data);
        this.setState({
          myPop: true,
          editData: data,
          roomNumber: data.roomNumber,
          AC: data.AC,
          status: data.status,
          guestHouse: data.guestHouse,
          rate: data.rate,
        });
      })
      .catch((error) => {
        console.error("Error fetching room data:", error);
      });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  //Update Room
  handleSaveEdit = (room) => {
    apiRequest(`/api/rooms/${room}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: this.state.status,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else if (res.status === 400) {
          return res.json().then((data) => {
            toast.error(data.error || "An error occurred. Please try again.");
            throw new Error(data.error || "Unknown error");
          });
        } else {
          throw new Error("Unexpected server response");
        }
      })
      .then((data) => {
        this.setState({
          myPop: false,
        });
        this.fetchRooms();
        toast.success("Room Updated successfully!");
      })
      .catch((error) => {
        if (error.message !== "Unknown error" && error.status !== 400) {
        }
      });
  };

  //   Delete Room
  handleDelete = (roomID) => {
    apiRequest(`/api/rooms/${roomID}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.ok) {
          toast.success(`Room deleted successfully`);
          this.fetchRooms();
        } else {
          toast.error("Error deleting room");
        }
      })
      .catch((error) => {
        console.error("Error deleting room:", error);
      });
  };

  handleSearchChange = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  handleACFilterChange = (event) => {
    this.setState({ filterAC: event.target.value });
  };

  handleGuestHouseFilterChange = (event) => {
    this.setState({ filterGuestHouse: event.target.value });
  };

  handleStatusFilterChange = (event) => {
    this.setState({ filterStatus: event.target.value });
  };
  handleOtherComponent = () => {
    this.setState({
      showComponentAdd: !this.state.showComponentAdd,
    });
  };
  handleSelectSeat = (selectedRoom) => {
    this.setState({ selectedRoom });
  };

  render() {
    const { selectedRoom } = this.props;

    const { rooms, searchTerm, filterAC, filterGuestHouse, filterStatus } =
      this.state;

    const filteredRooms = rooms.filter((item) => {
      const matchSearch = item.roomNumber.toString().includes(searchTerm);
      const matchAC = filterAC === "all" || item.AC === filterAC;
      const matchGuestHouse =
        filterGuestHouse === "all" || item.guestHouse === filterGuestHouse;
      const matchStatus =
        filterStatus === "all" || item.status === filterStatus;

      return matchSearch && matchAC && matchGuestHouse && matchStatus;
    });

    return (
      <div className="AllRoom">
                <h2 className="mb-5 text-center">Room Management</h2>

        <ToastContainer />
        <div className="roomfilter">
          <label>
            Search by Room Number:
            <br />
            <input 
              type="text"
              value={searchTerm}
              onChange={this.handleSearchChange}
            />
          </label>
          <label>
            Filter by AC:
            <br />

            <select value={filterAC} onChange={this.handleACFilterChange}>
              <option value="all">All</option>
              <option value="AC">AC</option>
              <option value="Non-AC">Non-AC</option>
            </select>
          </label>
          <label>
            Filter by Guest House:
            <br />

            <select
              value={filterGuestHouse}
              onChange={this.handleGuestHouseFilterChange}
            >
              <option value="all">All</option>
              <option value="ChandraBhaga">ChandraBhaga</option>
              <option value="KalyanMandap">KalyanMandap</option>
            </select>
          </label>
          <label>
            Filter by Status:
            <br />
            <select
              value={filterStatus}
              onChange={this.handleStatusFilterChange}
            >
              {/* <option value="all">All</option>
              <option value="available">Available</option>
              <option value="busy">Busy</option> */}
              <option value="notClean">Not Clean</option>
            </select>
          </label>
          {/* {this.state.showComponentAdd && <AddRoomCompo />}
          <button className="greenButton" onClick={this.handleOtherComponent}>
            Add New Room
          </button> */}
        </div>
        <div className="styled-table-container admintable">
          <table className="styled-table">
            <thead>
              <tr>
                <th>Room Number</th>
                <th>AC</th>
                <th>Status</th>
                <th>Guest House</th>
                {/* <th>Busy</th> */}
                <th>NotClean</th>
                <th>Available</th>
                {/* <th>Edit</th>
                <th>Delete</th> */}
              </tr>
            </thead>
            <tbody>
              {filteredRooms.map((item) => (
                <tr key={item._id}>
                  <td>{item.roomNumber}</td>
                  <td>{item.AC}</td>
                  <td className={item.status.toLowerCase()}>
                    {item.status.toLowerCase()}
                  </td>
                  <td>{item.guestHouse}</td>
                  {/* <td>
                    <button
                      className="btn btn-secondary"
                      onClick={(e) => {
                        this.setState({ status: "busy" }, () => {
                          this.handleSaveEdit(item.roomNumber); // Call fetchAllBookings after state is updated
                        });
                      }}
                    >
                      Busy
                    </button>
                  </td> */}
                  <td>
                    <button
                      className="btn btn-warning"
                      onClick={(e) => {
                        this.setState({ status: "notClean" }, () => {
                          this.handleSaveEdit(item.roomNumber); // Call fetchAllBookings after state is updated
                        });
                      }}
                    >
                      NotClean
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-success"
                      onClick={(e) => {
                        this.setState({ status: "available" }, () => {
                          this.handleSaveEdit(item.roomNumber); // Call fetchAllBookings after state is updated
                        });
                      }}                    >
                      Available
                    </button>
                  </td>
                  {/* <td>
                    <button className="btn btn-info" onClick={() => this.handleEdit(item.roomNumber)}>
                      Edit
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger"
                      onClick={() => this.handleDelete(item._id)}
                    >
                      Delete
                    </button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          {this.state.myPop && (
            <div className="myPop">
              <label>
                Edit Room Number:
                <input
                  type="text"
                  name="roomNumber"
                  value={this.state.roomNumber}
                  onChange={this.handleInputChange}
                />
              </label>

              <input
                type="number"
                name="rate"
                value={this.state.rate}
                onChange={this.handleInputChange}
              />
              <select
                name="AC"
                id=""
                value={this.state.AC}
                onChange={this.handleInputChange}
              >
                <option value="">Select</option>
                <option value="AC">Yes</option>
                <option value="Non-AC">No</option>
              </select>
              <select
                name="status"
                value={this.state.status}
                id=""
                onChange={this.handleInputChange}
              >
                <option value="">Select</option>
                <option value="Available">Available</option>
                <option value="Busy">Busy</option>
                <option value="NotClean">Not Clean</option>
              </select>
              <select
                name="guestHouse"
                value={this.state.guestHouse}
                id=""
                onChange={this.handleInputChange}
              >
                <option value="">Select</option>
                <option value="ChandraBhaga">ChandraBhaga</option>
                <option value="KalyanMandap">KalyanMandap</option>
              </select>
              <button onClick={this.handleSaveEdit}>Save</button>
            </div>
          )}
        </div>
        <hr />
        <RoomReport rooms={rooms} />
      </div>
    );
  }
}

export default RoomList;
