import React, { Component } from "react";
// import { withRouter } from "react-router-dom";

// Import All Steps
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./step4";
import Step5 from "./step5";
//Import Toastify
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

class AllStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      checkIn: "",
      checkOut: "",
      Adult: "",
      Child: "",
      AC: "",
      showBookingPop: false,
      closePopup: false,
      orderId: "",
      paymentId: "",
      selectedRoom: [],
      bookingData: {},
      isAdmin: true,
    };
  }
  handleClosePopup = () => {
    this.setState({
      step: 1,
    });
    this.props.closeOverlay();
  };
  handleCheckInOutProceed = (dates) => {
    const { checkIn, checkOut, Adult, Child, AC } = dates;
    this.setState({
      checkIn,
      checkOut,
      Adult,
      Child,
      AC,
    });
    fetch("/api/checkroom", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ checkIn, checkOut, Adult, Child, AC }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.AvailableRooms > 0) {
          let toastMeg = `We have ${data.AvailableRooms} Rooms Available Now.`;
          toast.success(toastMeg);
          if (
            localStorage.getItem("Bookingtoken") &&
            localStorage.getItem("Bookinguser")
          ) {
            this.setState({ step: 3 });
          } else {
            this.setState({ step: 2 });
          }
        } else {
          toast.info("No Available rooms.");
          this.handleClosePopup();
        }
      })
      .catch((error) => {
        toast.error("Error checking availability:");
        this.handleClosePopup();
        // Handle errors, e.g., show an error message to the user
      });
  };
  handleLoginSuccess = (data) => {
    if (data.token && data.user) {
      this.setState({
        step: 3,
      });
    } else {
      toast.info("Please Login");
    }
  };
  paymentDone = (response, data) => {
    if (response.orderId && response.paymentId && response.msg === "success") {
      this.setState({
        step: 5,
        bookingData: data || {},
        orderId: response.orderId || "",
        paymentId: response.paymentId || "",
      });
    }
    if (this.state.isAdmin) {
      // console.log("booking Created", data);
      // console.log("booking Created", response);
      this.setState({
        step: 5,
        bookingData: response || {},
        orderId: response.orderId || "",
        paymentId: response.paymentId || "",
      });
    } else {
      toast.info("Please Login");
    }
  };
  handleDataUpdate = (message, TotalAmount, TotalRoom, TotalMattress) => {
    // console.log(message, TotalAmount, TotalRoom, TotalMattress);
    if (message === "User updated successfully") {
      toast.success(message);
      this.setState({
        TotalAmount: TotalAmount,
        TotalRoom: TotalRoom,
        TotalMattress: TotalMattress,
        step: 4,
      });
    } else {
      toast.error(message);
    }
  };

  handleShow = () => {
    this.setState({
      showBookingPop: true,
    });
  };
  render() {
    const { step, checkIn, checkOut, Adult, Child, AC } = this.state;
    return (
      <div>
        {/* <ToastContainer></ToastContainer> */}
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {step === 1 && <Step1 onProceed={this.handleCheckInOutProceed} />}
        {step === 2 && (
          <Step2
            onLoginSuccess={this.handleLoginSuccess}
            handleClosePopup={this.handleClosePopup}
          />
        )}
        {step === 3 && (
          <Step3
            onProceed={this.handleDataUpdate}
            handleClosePopup={this.handleClosePopup}
            checkIn={checkIn}
            checkOut={checkOut}
            Adult={Adult}
            Child={Child}
            AC={AC}
          />
        )}
        {step === 4 && (
          <Step4
            onPaymentDone={this.paymentDone}
            handleClosePopup={this.handleClosePopup}
          ></Step4>
        )}
        {step === 5 && (
          <Step5
            orderId={this.state.orderId}
            paymentId={this.state.paymentId}
            bookingData={this.state.bookingData}
            handleClosePopup={this.handleClosePopup}
          ></Step5>
        )}
      </div>
    );
  }
}

export default AllStep;
