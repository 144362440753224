import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FloatingLabel,
} from "react-bootstrap";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { BsEye, BsEyeSlash } from "react-icons/bs";

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {
        name: "",
        email: "",
        password: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        phonenumber: "",
      },
      haveAC: false,
      closePopup: true,
      showPassword: false,
      isAdmin : true
    };
  }
  handleClosePopup = () => {
    this.props.handleClosePopup(this.state.closePopup);
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    // console.log(name, value);
    if (name.startsWith("userData")) {
      const filed = name.split(".")[1];
      this.setState({
        userData: {
          ...this.state.userData,
          [filed]: value,
        },
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  componentDidMount = () => {
    
  }
  haveAc = () => {
    this.setState((prevState) => ({
      haveAC: !prevState.haveAC,
    }));
  };

  handleLogin = async (event) => {
    event.preventDefault();
    const { email, password } = this.state.userData;

    if (!email || !password) {
      toast.error("Email and password are required.");
      return;
    }
    // Check if the email has a proper format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    await fetch("/api/admin/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email.toLowerCase(),
        password: password,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            toast.error(data.error || "An error occurred. Please try again.");
          });
        }
        return res.json();
      })

      .then((data) => {
        if (data && data.token !== undefined && data.user !== undefined) {
          localStorage.setItem("Bookingtoken", data.token);
          localStorage.setItem("Bookinguser", JSON.stringify(data.user));
          toast.success(`Welcome ${data.user.name}`);
          this.props.onLoginSuccess(data);
          // console.log(data)
        }
      });
  };
  getPassword  =  async (event)  =>  {
    event.preventDefault();
    const { email } = this.state.userData;

    if (!email ) {
      toast.error("Email required.");
      return;
    }
    // Check if the email has a proper format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    await fetch("/api/admin/auth/getUserPass", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email.toLowerCase(),
      }),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            toast.error(data.error || "An error occurred. Please try again.");
          });
        }
        return res.json();
      })
      
      .then((data) => {
        if (data && data.user !== undefined ) {
          localStorage.setItem("Bookingtoken", data.token);
          localStorage.setItem("Bookinguser", JSON.stringify(data.user));
          toast.success(`Welcome ${data.user.name}`);
          this.props.onLoginSuccess(data);
          // console.log(data)
        }
      });
  };
  
  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleRegister = (event) => {
    event.preventDefault();
    const {
      email,
      password,
      name,
      address,
      pincode,
      phonenumber,
      state,
      city,
    } = this.state.userData;
    if (
      !email ||
      !password ||
      !name ||
      !address ||
      !pincode ||
      !phonenumber ||
      !state ||
      !city
    ) {
      toast.error("All fields are required.");
      return;
    }
    // Check if the email has a proper format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    // Check if pincode is provided and has 6 digits
    if (!pincode || !/^\d{6}$/.test(pincode)) {
      toast.error("Please enter a valid 6-digit pincode.");
      return;
    }

    // Check if phonenumber is provided and has 10 digits
    if (!phonenumber || !/^\d{10}$/.test(phonenumber)) {
      toast.error("Please enter a valid 10-digit phone number.");
      return;
    }
    fetch("/api/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email.toLowerCase(),
        password: password,
        name: name,
        address: address,
        pincode: pincode,
        phonenumber: phonenumber,
        state: state,
        city: city,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            toast.error(data.error || "An error occurred. Please try again.");
          });
        }
        return res.json();
      })
      .then((data) => {
        // console.log(data);
        if (data && data.Bookingtoken !== undefined && data.Bookinguser !== undefined) {
          localStorage.setItem("Bookingtoken", data.token);
          localStorage.setItem("Bookinguser", JSON.stringify(data.user));
          toast.success("Login Successful");
          this.props.onLoginSuccess(data);
        }
      });
  };
  render() {

    return (
      <div>
        <ToastContainer></ToastContainer>
        <Container className="popup">
          <button onClick={this.handleClosePopup} className="popupCloseBtn">
            X
          </button>
          <h6 className="text-center ">Login / Create Account</h6>
          {this.state.haveAC && (
            <Row className="justify-content-center">
              <Col>
                <Form >
                  <Form.Group>
                    <FloatingLabel
                      controlId="floatingCheckIn"
                      label="Email"
                      className="mb-3"
                    >
                      <Form.Control
                        type="email"
                        name="userData.email"
                        onChange={this.handleInputChange}
                        placeholder="Email / Username"
                        required
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingCheckOut"
                      label="Password"
                      className="mb-3"
                    >
                      <Form.Control
                        type={this.state.showPassword ? "text" : "password"}
                        required
                        name="userData.password"
                        onChange={this.handleInputChange}
                        placeholder="Password"
                      />
                      <div
                        className="position-absolute end-0"
                        style={{ top: "1%", transform: "translateX(-10%)" }}
                      >
                        <Button
                          variant="link"
                          onClick={this.togglePasswordVisibility}
                        >
                          {this.state.showPassword ? <BsEyeSlash /> : <BsEye />}
                        </Button>
                      </div>
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingAdult"
                      label="Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        required
                        name="userData.name"
                        onChange={this.handleInputChange}
                        placeholder="Name"
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingChild"
                      label="address"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        required
                        name="userData.address"
                        onChange={this.handleInputChange}
                        placeholder="Address"
                      />
                    </FloatingLabel>
                    <FloatingLabel type="text" label="State" className="mb-3">
                      <Form.Control
                        type="text"
                        name="userData.state"
                        onChange={this.handleInputChange}
                        placeholder="State"
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingTotalRoom"
                      label="City"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        required
                        name="userData.city"
                        onChange={this.handleInputChange}
                        placeholder="City"
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingTotalMattress"
                      label="Contact Number"
                      className="mb-3"
                    >
                      <Form.Control
                        type="number"
                        required
                        name="userData.phonenumber"
                        onChange={this.handleInputChange}
                        placeholder="9696969696"
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingTotalAmount"
                      label="Pin Code"
                      className="mb-3"
                    >
                      <Form.Control
                        type="number"
                        required
                        name="userData.pincode"
                        onChange={this.handleInputChange}
                        placeholder="Pincode"
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          )}
          {this.state.haveAC === false && (
            <Row className="justify-content-center">
              <Col>
                <Form className="bookingForm">
                  <Form.Group>
                    <FloatingLabel
                      controlId="floatingCheckIn"
                      label="Email"
                      className="mb-3"
                    >
                      <Form.Control
                        type="email"
                        name="userData.email"
                        onChange={this.handleInputChange}
                        placeholder="user@gmail.com"
                        required
                      />
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingCheckOut"
                      label="Password"
                      className="mb-3"
                    >
                      <Form.Control
                        type={this.state.showPassword ? "text" : "password"}
                        required
                        name="userData.password"
                        onChange={this.handleInputChange}
                        placeholder="Password"
                      />
                    </FloatingLabel>
                    <div
                      className="position-absolute end-0"
                      style={{ top: "50%", transform: "translateX(-50%)" }}
                    >
                      <Button
                        variant="link"
                        onClick={this.togglePasswordVisibility}
                      >
                        {this.state.showPassword ? <BsEyeSlash /> : <BsEye />}
                      </Button>
                    </div>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              {this.state.haveAC === true ? (
                <Button
                  className="d-block m-auto"
                  variant="primary"
                  onClick={this.haveAc}
                >
                  Login
                </Button>
              ) : (
                <Button
                  className="d-block m-auto"
                  variant="primary"
                  onClick={this.getPassword}
                >
                  Login
                </Button>
              )}
            </Col>
            <Col>
              {this.state.haveAC === false ? (
                <Button
                  className="d-block m-auto"
                  variant="primary"
                  onClick={this.haveAc}
                >
                  Create Account
                </Button>
              ) : (
                <Button
                  className="d-block m-auto"
                  variant="primary"
                  onClick={this.handleRegister}
                >
                  Create Account
                </Button>
              )}
            </Col>
            {this.state.isAdmin &&  
              <button className="btn btn-primary" onClick={this.getPassword}>Login</button>
            }
          </Row>
        </Container>
      </div>
    );
  }
}

export default Step2;
