import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Container, Row, Col, Form, Button, FloatingLabel } from "react-bootstrap";

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkIn: "",
      checkOut: "",
      Adult: "",
      AC: "",
      Child: "",
      showBookingPop: true,
    };
  }
  handleProceed = () => {
    const { checkIn, checkOut, Adult, AC, Child, showBookingPop } = this.state;

    if (checkIn !== "" && checkOut !== "" && Adult !== "" && AC !== "" && Child !== "") {
      this.props.onProceed({ checkIn, checkOut, Adult, AC, Child, showBookingPop });
    } else {
      toast.info("Please fill all the fields");
    }
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    const currentDate = new Date();
    const threeMonthsLater = new Date();
    threeMonthsLater.setMonth(currentDate.getMonth() + 3);

    if (name === "checkIn") {
      const selectedDate = new Date(value);
      
      // Check if the selected date is before today
      if (selectedDate < new Date(currentDate.toDateString())) {
        toast.error("Check In date cannot be before today");
        return;
      }
    
      // Check if the time difference is less than 2 hours for Check-In and Check-Out
      const timeDifference = selectedDate.getTime() - currentDate.getTime();
      const hoursDifference = timeDifference / (1000 * 3600);
    
      // if (hoursDifference < 2) {
      //   toast.error("Check In should be at least 2 hours from now");
      //   return;
      // }
    }

    if (name === "checkOut" && new Date(value) < currentDate) {
      toast.error("Check Out date cannot be before today");
      return;
    }

    if (name === "checkOut" && new Date(value) > threeMonthsLater) {
      toast.error("Check Out date cannot be more than 3 months from today");
      return;
    }

    if (name === "checkOut" && new Date(value) < new Date(this.state.checkIn)) {
      toast.error("Check Out date cannot be before Check In date");
      return;
    }

    this.setState({
      [name]: value,
    });
  };

  render() {
    const { checkIn, checkOut, Adult, AC, Child } = this.state;
    let threeMonthsLater = new Date();

    return (
      <Container className="step1">
        <ToastContainer></ToastContainer>
        <Row className="justify-content-center ">
          <Col className="step-1">
            <Form className="bookingForm ">
              <Form.Group className="step1-flex">
                <FloatingLabel controlId="checkIn" label="Check In">
                  <Form.Control
                    type="datetime-local"
                    name="checkIn"
                    value={checkIn}
                    onChange={this.handleInputChange}
                    min={new Date().toISOString().split("T")[0]}
                    placeholder="Check In"
                  />
                </FloatingLabel>

                <FloatingLabel controlId="checkOut" label="Check Out">
                  <Form.Control
                    type="datetime-local"
                    name="checkOut"
                    value={checkOut}
                    onChange={this.handleInputChange}
                    min={checkIn} // Set min to the selected Check In date
                    max={threeMonthsLater.toISOString().split("T")[0]} // Set max to 3 months from Check In date
                    placeholder="Check Out"
                  />
                </FloatingLabel>

                <FloatingLabel controlId="Adult" label="Adult">
                  <Form.Control
                    type="number"
                    name="Adult"
                    value={Adult}
                    onChange={this.handleInputChange}
                    placeholder="Adult"
                  />
                </FloatingLabel>

                <FloatingLabel controlId="Child" label="Child">
                  <Form.Control
                    type="number"
                    name="Child"
                    value={Child}
                    onChange={this.handleInputChange}
                    placeholder="Child"
                  />
                </FloatingLabel>

                <FloatingLabel controlId="AC" label="AC">
                  <Form.Select
                    name="AC"
                    value={AC}
                    onChange={(e) => this.setState({ AC: e.target.value })}
                    placeholder="Select Type"
                  >
                    <option value="">Select Type</option>
                    <option value="ac">AC</option>
                    <option value="non-ac">Non-AC</option>
                  </Form.Select>
                </FloatingLabel>
                <Row className="justify-content-center">
                  <Col>
                    <Button variant="primary" onClick={this.handleProceed}>
                      Check Availability
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Step1;
