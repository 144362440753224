// AdminRoute.js
import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Navbar from '../pages/Superadmin/navbar'
import  '../pages/Superadmin/custom.css'
const AdminRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, user } = useAuth();

  useEffect(() => {
    // You can add additional logic here if needed
  }, [isAuthenticated, user]);
  
  return (
    <Route
      {...rest}
      render={(props) =>
          isAuthenticated && user && user.role === 'superAdmin' ? (
          <div className="container123">
            <Navbar/>
            <div className="content-123 w-100">
            <Component {...props} />
            </div>
          </div>

        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default AdminRoute;
