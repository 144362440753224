import React from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import {Link} from "react-router-dom"
import Logo from '../images/Logo.png'
class NavbarTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      showPop: false,
    };
  }
  handleMenuClick = () => {
    this.setState((prevState) => ({
      showMenu: !prevState.showMenu,
    }));
  };
  handleMouseEnterShowPop = () => {
    this.setState({
      showPop: true,
    });
  };
  handleMouseLeavePop = () => {
    this.setState({
      showPop: false,
    });
  };
  componentDidMount = () => {
    if(localStorage.getItem("token")) {
        this.setState({
            loggedIn  : true
        })
    }
    else {
        this.setState({
            loggedIn  : false
        })
    }
  }
  render() {
    const { showMenu, showPop, loggedIn} = this.state;
    return (
      <>
        <div className={showPop === true ? "overlay" : ""}></div>
        {showMenu && (
          <Navbar expand="lg" sticky="top">
            <Navbar.Brand className="mx-3" href="#">
            <img src={Logo}alt="" />
            <span>ISKCON Pandharpur</span>
              
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className={showMenu ? "show" : ""}
            >
              <Nav className="mr-auto ">
                <div className="menu">

                <Link to="/">Home</Link>
                <Link to="#">About</Link>
                <Link to="#">Services</Link>
                </div>
                {/* Add more Nav.Link components as needed */}
              </Nav>
              <div className="move-right">
                <Nav>
                  {loggedIn ? (
                    <NavDropdown title="User Profile" id="basic-nav-dropdown">
                      <NavDropdown.Item href="#">Profile</NavDropdown.Item>
                      <NavDropdown.Item href="#">Settings</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="#">Logout</NavDropdown.Item>
                    </NavDropdown>
                  ) : (
                    <div className="loginsignup">
                    <Button variant="primary" href="/login" className="loginButton">
                      Login
                    </Button>
                    <Button variant="primary" href="#" className="singUpbutton">
                      SignUp
                    </Button>
                    </div>
                  )}
                </Nav>
              </div>
            </Navbar.Collapse>
          </Navbar>
        )}
        <div>
        {showMenu && (
        <Button
            className={"menu-btn hideMenu"}
            onClick={this.handleMenuClick}
          >
            x
          </Button>
        )}
        {showMenu === false && (
        <Button
            className={"menu-btn hideMenu"}
            onClick={this.handleMenuClick}
          >
            =
          </Button>
        )}
        </div>
      </>
    );
  }
}

export default NavbarTop;
