import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FloatingLabel,
  Spinner,
} from "react-bootstrap";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {
        name: "",
        email: "",
        password: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        phonenumber: "",
      },
      showPassword: false,
      isLogin: true, // Initially show the login form
      forgotpass: false,
      fpassotpsend: false,
      fpassotp: "",
      token: "",
      newPassword: "",
      loading: false,
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...this.state,
      [name]: value,
      userData: {
        ...this.state.userData,
        [name]: value,
      },
    });
  };

  fpassotp = async () => {
    this.setState({ loading: true });
    try {
      const response = await fetch('/api/admin/auth/forgotPassword', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.state.userData.email,
        }),
      });
      const data = await response.json();
      if (data.message === "OTP sent successfully") {
        this.setState({
          fpassotpsend: true,
          loading: false,
        });
        toast.success("OTP sent successfully");
      } else {
        this.setState({ loading: false });
        toast.error(data.message);
      }
    } catch (error) {
      console.error('There was an error!', error);
      this.setState({ loading: false });
      toast.error('There was an error sending OTP');
    }
  };

  fverifyotp = async () => {
    this.setState({ loading: true });
    try {
      const response = await fetch('/api/admin/auth/forgotPasswordVerify', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.state.userData.email,
          otp: this.state.fpassotp,
        }),
      });
      const data = await response.json();
      if (data.token) {
        this.setState({
          token: data.token,
          fpassotpsend: false,
          loading: false,
        });
        toast.success("OTP verified successfully. You can now reset your password.");
      } else {
        this.setState({ loading: false });
        toast.error(data.message);
      }
    } catch (error) {
      console.error('There was an error!', error);
      this.setState({ loading: false });
      toast.error('There was an error verifying OTP');
    }
  };

  resetPassword = async () => {
    this.setState({ loading: true });
    try {
      const response = await fetch('/api/admin/auth/forgotPasswordReset', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: this.state.token,
          newPassword: this.state.newPassword,
        }),
      });
      const data = await response.json();
      if (data.message === "Password reset successfully") {
        toast.success("Password reset successfully. Please login with your new password.");
        this.setState({
          forgotpass: false,
          isLogin: true,
          token: "",
          newPassword: "",
          loading: false,
        });
      } else {
        this.setState({ loading: false });
        toast.error(data.message);
      }
    } catch (error) {
      console.error('There was an error!', error);
      this.setState({ loading: false });
      toast.error('There was an error resetting password');
    }
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleFormSwitch = (isLogin) => {
    this.setState({
      isLogin,
      forgotpass: false,
      token: "",
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = this.state.userData;

    // Validation checks
    if (!email || !password) {
      toast.error("Email and password are required.");
      return;
    }

    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(email)) {
    //   toast.error("Please enter a valid email address.");
    //   return;
    // }

    this.setState({ loading: true });

    // Perform login or registration based on the current mode
    const url = this.state.isLogin ? "/api/admin/auth/login" : "/api/register";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email.toLowerCase(),
          password: password,
          ...(!this.state.isLogin && this.state.userData), // Include additional fields for registration
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "An error occurred. Please try again.");
      }

      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user));
      toast.success(`Welcome ${data.user.name}`);
      this.props.onLoginSuccess(data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { forgotpass, fpassotpsend, token, loading } = this.state;
    return (
      <div>
        <ToastContainer />
        {!forgotpass && (
          <Container className="popup">
            <h6 className="text-center mb-4">Login / Create Account</h6>
            <div className="mb-3">
              <Button
                variant="secondary"
                onClick={() => this.handleFormSwitch(true)}
                className={this.state.isLogin ? "active me-2" : "me-2"}
              >
                Login
              </Button>
              <Button
                variant="secondary"
                onClick={() => this.handleFormSwitch(false)}
                className={!this.state.isLogin ? "active" : ""}
              >
                Signup
              </Button>
            </div>
            <Form onSubmit={this.handleSubmit}>
              {!this.state.isLogin && (
                <>
                  <FloatingLabel controlId="floatingName" label="Name">
                    <Form.Control
                      type="text"
                      name="name"
                      value={this.state.userData.name}
                      onChange={this.handleInputChange}
                      placeholder="Name"
                      required
                    />
                  </FloatingLabel>
                  <FloatingLabel controlId="floatingAddress" label="Address">
                    <Form.Control
                      type="text"
                      name="address"
                      value={this.state.userData.address}
                      onChange={this.handleInputChange}
                      placeholder="Address"
                      required
                    />
                  </FloatingLabel>
                  <Row className="mb-3">
                    <Col>
                      <FloatingLabel controlId="floatingState" label="State">
                        <Form.Control
                          type="text"
                          name="state"
                          value={this.state.userData.state}
                          onChange={this.handleInputChange}
                          placeholder="State"
                          required
                        />
                      </FloatingLabel>
                    </Col>
                    <Col>
                      <FloatingLabel controlId="floatingCity" label="City">
                        <Form.Control
                          type="text"
                          name="city"
                          value={this.state.userData.city}
                          onChange={this.handleInputChange}
                          placeholder="City"
                          required
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <FloatingLabel controlId="floatingPincode" label="Pincode">
                    <Form.Control
                      type="number"
                      name="pincode"
                      value={this.state.userData.pincode}
                      onChange={this.handleInputChange}
                      placeholder="Pincode"
                      required
                    />
                  </FloatingLabel>
                  <FloatingLabel controlId="floatingPhoneNumber" label="Phone Number">
                    <Form.Control
                      type="number"
                      name="phonenumber"
                      value={this.state.userData.phonenumber}
                      onChange={this.handleInputChange}
                      placeholder="Phone Number"
                      required
                    />
                  </FloatingLabel>
                </>
              )}
              <FloatingLabel controlId="floatingEmail" label="Email or Phone Number">
                <Form.Control
                  type="text"
                  name="email"
                  value={this.state.userData.email}
                  onChange={this.handleInputChange}
                  placeholder="Email or Phone Number"
                  required
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingPassword" label="Password">
                <Form.Control
                  type={this.state.showPassword ? "text" : "password"}
                  name="password"
                  value={this.state.userData.password}
                  onChange={this.handleInputChange}
                  placeholder="Password"
                  required
                />
                <Button
                  variant="link"
                  onClick={this.togglePasswordVisibility}
                  className="password-toggle-btn"
                >
                  {this.state.showPassword ? <BsEyeSlash /> : <BsEye />}
                </Button>
              </FloatingLabel>
              <div className="d-grid gap-2 mt-4">
                <Button variant="primary" type="submit" disabled={loading}>
                  {loading ? <Spinner animation="border" size="sm" /> : (this.state.isLogin ? "Login" : "Signup")}
                </Button>
              </div>
              {!this.state.isLogin && (
                <div className="mt-3 text-center">
                  Already have an account?{" "}
                  <Button
                    variant="link"
                    onClick={() => this.handleFormSwitch(true)}
                    className="text-decoration-none p-0 m-0"
                  >
                    Login here
                  </Button>
                </div>
              )}
              {this.state.isLogin && (
                <div className="mt-3 text-center">
                  Don't have an account?{" "}
                  <Button
                    variant="link"
                    onClick={() => this.setState({ forgotpass: true })}
                    className="text-decoration-none m-0 p-0"
                  >
                    Forgot Password?
                  </Button>
                </div>
              )}
            </Form>
          </Container>
        )}
        {forgotpass && !token && (
          <Container className="popup">
            <h6 className="text-center mb-4">Forgot Password</h6>
            <label htmlFor="email">Enter Your Email:</label>
            <FloatingLabel controlId="floatingEmail" label="Email">
              <Form.Control
                type="text"
                name="email"
                value={this.state.userData.email}
                onChange={this.handleInputChange}
                placeholder="Email or Phone Number"
                required
              />
            </FloatingLabel>
            {fpassotpsend && (
              <div>
                <label htmlFor="otp">Enter Your OTP:</label>
                <FloatingLabel controlId="floatingOtp" label="OTP">
                  <Form.Control
                    type="text"
                    name="fpassotp"
                    value={this.state.fpassotp}
                    onChange={this.handleInputChange}
                    placeholder="OTP"
                    required
                  />
                </FloatingLabel>
              </div>
            )}
            <div className="d-grid gap-2 mt-4">
              <Button
                variant="primary"
                onClick={fpassotpsend ? this.fverifyotp : this.fpassotp}
                disabled={loading}
              >
                {loading ? <Spinner animation="border" size="sm" /> : (fpassotpsend ? "Verify OTP" : "Send OTP")}
              </Button>
            </div>
          </Container>
        )}
        {token && (
          <Container className="popup">
            <h6 className="text-center mb-4">Reset Password</h6>
            <label htmlFor="newPassword">Enter New Password:</label>
            <FloatingLabel controlId="floatingNewPassword" label="New Password">
              <Form.Control
                type="password"
                name="newPassword"
                value={this.state.newPassword}
                onChange={this.handleInputChange}
                placeholder="New Password"
                required
              />
            </FloatingLabel>
            <div className="d-grid gap-2 mt-4">
              <Button variant="primary" onClick={this.resetPassword} disabled={loading}>
                {loading ? <Spinner animation="border" size="sm" /> : "Reset Password"}
              </Button>
            </div>
          </Container>
        )}
      </div>
    );
  }
}

export default Step2;
