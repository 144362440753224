import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <>
        <div className="footer">
          <div>
            <span>Address</span>
            <p>
              Shree Shree Radha Pandharinath
              <br />
              East bank of Chandrabhaga River, Pandharpur Dist- Solapur
              <br />
              Pin-no : 413304;
              <br />
              Reception No : 91+ 9075220180
            </p>
          </div>
          <div>
            <span>Quick Links</span>
            <div className="qli">
            <li>Home </li>
            <li>About</li>
            <li>Contact</li>
            </div>
          </div>
          <div>
            <span>Contact Us</span>
            <p>
              Phone : +91 9075220180
              <br />
              Email : booking@iskconpandharpur.co
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default Footer;
