import React from "react";
import { Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiRequest from "../../../routes/ApiService";
import { handleRazorpay } from "../../payment/Razorpay";
import Rotate from "./Rotate.mp4";
class Step4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rooms: [],
      selectedRooms: [],
      bookingData: {
        checkIn: "",
        checkOut: "",
        Adult: "",
        Child: "",
        RoomType: "",
        TotalAmount: "",
        TotalMattress: "",
        TotalRoom: "",
        selectedRooms: [],
        bookingId: "",
      },
      userId: "",
      email: "",
      filterRoomNumber: "",
      filterGuestHouse: "",
      filterStatus: "",
      PaymentStatus: "",
      busyRooms: new Set(), // Set to store busy room numbers
      isMobile: false, // flag to detect if the device is a mobile
      forceMob: false, // flag to
      isAdmin: false,
    };
  }
  adminPay = () => {
    apiRequest("/api/booking", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: this.state.userId,
        selectedRoom: this.state.selectedRooms,
        bookingData: {
          checkIn: this.state.bookingData.checkIn,
          checkOut: this.state.bookingData.checkOut,
          TotalAmount: this.state.bookingData.TotalAmount,
          Adult: this.state.bookingData.Adult,
          AC: this.state.bookingData.RoomType,
          TotalRoom: this.state.bookingData.TotalRoom,
          selectedRoom: this.state.selectedRooms,
          Child: this.state.bookingData.Child,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.props.onPaymentDone(data);
      })
      .catch((error) => console.error("Error creating booking:", error));
  };
  handleResize = () => {
    const { forceMob } = this.state;
    if (forceMob) {
      window.removeEventListener("resize", this.handleResize); // Remove event listener when component unmount
    } else {
      window.addEventListener("resize", this.handleResize); // Remove event listener when component unmount
      const isMobile = window.innerWidth <= 768; // Set the breakpoint according to your design
      this.setState({ isMobile });
    }
  };
  handleClosePopup = () => {
    this.setState({
      closePopup: true,
    });
    this.props.handleClosePopup(this.state.closePopup);
  };
  handlePayment = async () => {
    const { bookingData, userId } = this.state;
    try {
      const response = await fetch("/api/create-order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          UserId: userId,
          receipt: this.state.email,
          amount: bookingData.TotalAmount,
          bookingData: {
            Adult: bookingData.Adult,
            RoomType: bookingData.RoomType,
            TotalRoom: bookingData.TotalRoom,
            selectedRooms: bookingData.selectedRooms,
            checkIn: bookingData.checkIn,
            checkOut: bookingData.checkOut,
            Child: bookingData.Child,
          },
        }),
      });
      const data = await response.json();
      if (data && data.order_id) {
        this.setState({
          order_id: data.order_id,
        });
        console.log(data);
        handleRazorpay(data.order_id, bookingData.TotalAmount, (response) => {
          if (response.orderId && response.paymentId) {
            console.log(response.paymentId);
            apiRequest("/api/booking", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userId: userId,
                selectedRoom: this.state.selectedRooms,
                bookingData: {
                  checkIn: bookingData.checkIn,
                  checkOut: bookingData.checkOut,
                  TotalAmount: bookingData.TotalAmount,
                  Adult: bookingData.Adult,
                  AC: bookingData.RoomType,
                  TotalRoom: bookingData.TotalRoom,
                  selectedRoom: this.state.selectedRooms,
                  Child: bookingData.Child,
                },
                paymentData: {
                  orderId: response.orderId,
                  paymentId: response.paymentId,
                  paymentType: "Online",
                  paymentDate: new Date(),
                },
              }),
            })
              .then((response) => response.json())
              .then((data) => {
                console.log("booking Created", data);
                this.props.onPaymentDone(response, data);
              })

              .catch((error) =>
                console.error("Error creating booking:", error)
              );
          }
        });
      } else {
        toast.error("Error occurred while creating order");
      }
    } catch (error) {
      console.error("Error during payment:", error);
    }
  };

  fetchRooms() {
    apiRequest("/api/rooms", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => this.setState({ rooms: data }))
      .catch((error) => console.error("Error fetching rooms:", error));
  }
  componentDidMount = () => {
    const bookingData = JSON.parse(localStorage.getItem("bookingData"));
    const userData = JSON.parse(localStorage.getItem("user"));
    this.handleResize(); // Call handleResize initially

    this.setState(
      {
        bookingData: { ...bookingData },
        userId: userData._id,
        email: userData.email,
      },
      () => {
        this.fetchRooms();
        this.fetchBookings(); // Fetch booking data after fetching rooms
      }
    );
  };

  fetchBookings() {
    const { bookingData } = this.state;
    const checkInDate = new Date(bookingData.checkIn);
    const checkOutDate = new Date(bookingData.checkOut);

    apiRequest("/api/booking", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((bookings) => {
        const busyRooms = new Set(); // Set to store busy room numbers
        bookings.forEach((booking) => {
          const bookingCheckIn = new Date(booking.bookingData.checkIn);
          const bookingCheckOut = new Date(booking.bookingData.checkOut);
          // Check if the booking overlaps with the selected check-in and check-out dates
          if (
            (checkInDate <= bookingCheckOut && checkInDate >= bookingCheckIn) ||
            (checkOutDate >= bookingCheckIn &&
              checkOutDate <= bookingCheckOut) ||
            (checkInDate <= bookingCheckIn && checkOutDate >= bookingCheckOut)
          ) {
            booking.selectedRoom.forEach((roomNumber) => {
              busyRooms.add(roomNumber); // Add busy room numbers to the set
            });
          }
        });
        this.setState({ busyRooms }); // Update state with busy room numbers
      })
      .catch((error) => console.error("Error fetching bookings:", error));
  }

  handleSelectSeat = (selectedRoom) => {
    const { selectedRooms, busyRooms, bookingData } = this.state;
    const TotalRoom = bookingData.TotalRoom;

    if (
      selectedRoom.status.toLowerCase() !== "" &&
      selectedRoom.status.toLowerCase() !== "notclean"
    ) {
      if (busyRooms.has(selectedRoom.roomNumber)) {
        // Room is already booked for the selected dates
        toast.error(
          `${selectedRoom.roomNumber} | This room is already booked for the selected dates. Please select a different room.`
        );
      } else if (
        selectedRoom.AC.toLowerCase() !== bookingData.RoomType.toLowerCase()
      ) {
        // Room type doesn't match the selected RoomType
        toast.warn(
          `${selectedRoom.roomNumber} | This room is not available for you because you selected ${bookingData.RoomType} Rooms. Please select a only ${bookingData.RoomType} rooms.`
        );
      } else if (selectedRooms.includes(selectedRoom.roomNumber)) {
        // Room is already selected, so deselect it
        this.setState((prevState) => ({
          selectedRooms: prevState.selectedRooms.filter(
            (room) => room !== selectedRoom.roomNumber
          ),
        }));
      } else if (selectedRooms.length < parseInt(TotalRoom, 10)) {
        // Room is not selected, and we can select more rooms
        this.setState((prevState) => ({
          selectedRooms: [...prevState.selectedRooms, selectedRoom.roomNumber],
        }));
      } else {
        // Maximum number of rooms already selected
        toast.error("You have already selected the maximum number of rooms.");
      }
    } else {
      // Room is busy or not clean
      toast.error(
        `${selectedRoom.roomNumber} | This room is currently busy or not clean. Please select a different room.`
      );
    }
  };
  notRotate = () => {
    this.setState({
      isMobile: false,
      forceMob: true,
    });
  };
  render() {
    const {
      rooms,
      selectedRooms,
      filterRoomNumber,
      filterGuestHouse,
      filterStatus,
      busyRooms,
    } = this.state;

    // Filter rooms based on selected criteria
    let filteredRooms = rooms.filter(
      (room) =>
        // room.AC.toLowerCase() === this.state.bookingData.RoomType.toLowerCase()
        room
    );

    if (filterRoomNumber) {
      filteredRooms = filteredRooms.filter((room) =>
        room.roomNumber.includes(filterRoomNumber)
      );
    }

    if (filterGuestHouse) {
      filteredRooms = filteredRooms.filter(
        (room) => room.guestHouse === filterGuestHouse
      );
    }

    if (filterStatus) {
      filteredRooms = filteredRooms.filter(
        (room) => room.status.toLowerCase() === filterStatus.toLowerCase()
      );
    }

    // Sort filtered rooms by room number
    const sortedFilteredRooms = filteredRooms.sort((a, b) => {
      return parseInt(a.roomNumber) - parseInt(b.roomNumber);
    });

    // Group rooms by guest house
    const groupedRooms = {};
    sortedFilteredRooms.forEach((room) => {
      if (!groupedRooms[room.guestHouse]) {
        groupedRooms[room.guestHouse] = [];
      }
      groupedRooms[room.guestHouse].push(room);
    });
    const { isMobile } = this.state;

    if (isMobile) {
      return (
        <div className="popup">
          <h6 className="rotatetitle">
            For Best Experience Of Room Selection Please Rotate Your Device
          </h6>
          <video width="100%" height="100%" autoPlay loop>
            <source src={Rotate} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <button className="btn btn-primary" onClick={this.notRotate}>
            Proceed Without Rotate
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <ToastContainer />
          <Container className="popup step4">
            {/* <Row className="mb-3 filterRoom">
              <Col>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Room Number"
                    value={filterRoomNumber}
                    onChange={(e) =>
                      this.setState({ filterRoomNumber: e.target.value })
                    }
                  />
                </InputGroup>
              </Col>
              <Col>
                <InputGroup>
                  <Form.Select
                    value={filterGuestHouse}
                    onChange={(e) =>
                      this.setState({ filterGuestHouse: e.target.value })
                    }
                  >
                    <option value="">Guest House</option>
                    <option value="KalyanMandap">Kalyan Mandap</option>
                    <option value="ChandraBhaga">Chandra Bhaga</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col>
                <InputGroup>
                  <Form.Select
                    value={filterStatus}
                    onChange={(e) =>
                      this.setState({ filterStatus: e.target.value })
                    }
                  >
                    <option value="">Status</option>
                    <option value="busy">Busy</option>
                    <option value="available">Available</option>
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row> */}
            {/* <div className="statusRoom">
              <span className="busyroom">Busy Room</span>
              <span className="disabledroom">Different Room Type</span>
              <span className="availableroom">Available</span>
            </div>
            <div className="selectHouse">
              {Object.entries(groupedRooms).map(
                ([guestHouse, guestHouseRooms]) => (
                  <div key={guestHouse} className={`md-4`}>
                    <div className="guesthousename">
                      <span className="mb-3  guesthouse">
                        {guestHouse} Guest House
                      </span>
                    </div>
                    <div className="d-flex flex-wrap">
                      {guestHouse === "ChandraBhaga" && (
                        <div className="chandrabhaga">
                          <h6>First Floor</h6>
                          <div className="firstFloor">
                            <span className="label top">North</span>
                            <span className="label right">East</span>
                            <span className="label bottom">South</span>
                            <span className="label left">West</span>
                            <div className="row">
                              {[
                                1, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15,
                                14,
                              ].map((roomNumber) => {
                                const room = guestHouseRooms.find(
                                  (room) =>
                                    room.roomNumber === roomNumber.toString()
                                );
                                return (
                                  room && (
                                    <div
                                      key={room._id}
                                      className={`roomSquareUser   ${
                                        room.AC.toLowerCase() ===
                                        this.state.bookingData.RoomType.toLowerCase()
                                          ? ""
                                          : "disabled"
                                      }
                                      ${
                                        room.status.toLowerCase() === "notclean"
                                          ? "notclean"
                                          : ""
                                      }
                                      ${
                                        busyRooms.has(room.roomNumber)
                                          ? "busy"
                                          : "available"
                                      } ${
                                        selectedRooms.includes(room.roomNumber)
                                          ? "selectedRoom animated"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        this.handleSelectSeat(room)
                                      }
                                    >
                                      {selectedRooms.includes(
                                        room.roomNumber
                                      ) && (
                                        <div className="selectedtitle">
                                          Selected
                                        </div>
                                      )}
                                      {room.roomNumber}
                                    </div>
                                  )
                                );
                              })}
                            </div>
                            <div className="row">
                              {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map(
                                (roomNumber) => {
                                  const room = guestHouseRooms.find(
                                    (room) =>
                                      room.roomNumber === roomNumber.toString()
                                  );
                                  return (
                                    room && (
                                      <div
                                        key={room._id}
                                        className={`roomSquareUser  ${
                                          room.AC.toLowerCase() ===
                                          this.state.bookingData.RoomType.toLowerCase()
                                            ? ""
                                            : "disabled"
                                        } 
                                        ${
                                          room.status.toLowerCase() === "notclean"
                                            ? "notclean"
                                            : ""
                                        }
                                        ${
                                          busyRooms.has(room.roomNumber)
                                            ? "busy"
                                            : "available"
                                        } ${
                                          selectedRooms.includes(
                                            room.roomNumber
                                          )
                                            ? "selectedRoom animated"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          this.handleSelectSeat(room)
                                        }
                                      >
                                        {selectedRooms.includes(
                                          room.roomNumber
                                        ) && (
                                          <div className="selectedtitle">
                                            Selected
                                          </div>
                                        )}
                                        {room.roomNumber}
                                      </div>
                                    )
                                  );
                                }
                              )}
                            </div>
                          </div>
                          <h6>Second Floor</h6>
                          <div className="firstFloor">
                            <span className="label top">North</span>
                            <span className="label right">East</span>
                            <span className="label bottom">South</span>
                            <span className="label left">West</span>
<div className="row">
                              {[
                                26, 46, 45, 999, 44, 43, 42, 41, 40, 39, 38,
                              ].map((roomNumber) => {
                                const room = guestHouseRooms.find(
                                  (room) =>
                                    room.roomNumber === roomNumber.toString()
                                );
                                const roomDisplay =
                                  roomNumber === 999 ? (
                                    <div
                                      className="roomSquareUser"
                                      style={{
                                        textAlign: "Center",
                                        cursor: "Not-Allowed",
                                      }}
                                    >
                                      Open Area
                                    </div>
                                  ) : (
                                    room.roomNumber
                                  );
                                return (
                                  room && (
                                    <div
                                      key={room._id}
                                      className={`roomSquareUser ${
                                        room.AC.toLowerCase() ===
                                        this.state.bookingData.RoomType.toLowerCase()
                                          ? ""
                                          : "disabled"
                                      }
          ${room.status.toLowerCase() === "notclean" ? "notclean" : ""}
          ${busyRooms.has(room.roomNumber) ? "busy" : "available"} ${
                                        selectedRooms.includes(room.roomNumber)
                                          ? "selectedRoom animated"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        this.handleSelectSeat(room)
                                      }
                                    >
                                      {selectedRooms.includes(
                                        room.roomNumber
                                      ) && (
                                        <div className="selectedtitle">
                                          Selected
                                        </div>
                                      )}
                                      {roomDisplay}
                                    </div>
                                  )
                                );
                              })}
                            </div>                            <div className="row">
                              {[27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37].map(
                                (roomNumber) => {
                                  const room = guestHouseRooms.find(
                                    (room) =>
                                      room.roomNumber === roomNumber.toString()
                                  );
                                  return (
                                    room && (
                                      <div
                                        key={room._id}
                                        className={`roomSquareUser  ${
                                          room.AC.toLowerCase() ===
                                          this.state.bookingData.RoomType.toLowerCase()
                                            ? ""
                                            : "disabled"
                                        }  
                                        ${
                                          room.status.toLowerCase() === "notclean"
                                            ? "notclean"
                                            : ""
                                        }
                                        ${
                                          busyRooms.has(room.roomNumber)
                                            ? "busy"
                                            : "available"
                                        } ${
                                          selectedRooms.includes(
                                            room.roomNumber
                                          )
                                            ? "selectedRoom animated"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          this.handleSelectSeat(room)
                                        }
                                      >
                                        {selectedRooms.includes(
                                          room.roomNumber
                                        ) && (
                                          <div className="selectedtitle">
                                            Selected
                                          </div>
                                        )}
                                        {room.roomNumber}
                                      </div>
                                    )
                                  );
                                }
                              )}
                            </div>
                          </div>
                          <br />
                        </div>
                      )}

                      {guestHouse === "KalyanMandap" && (
                        <div className="chandrabhaga">
                          <h6 className="text-center">Ground Floor</h6>
                          <div className="firstFloor">
                            <span className="label top">North</span>
                            <span className="label right">East</span>
                            <span className="label bottom">South</span>
                            <span className="label left">West</span>
                            <div className="row">
                              {[
                                110, 109, 108, 107, 106, 105, 104, 103, 102,
                                101,
                              ].map((roomNumber) => {
                                const room = guestHouseRooms.find(
                                  (room) =>
                                    room.roomNumber === roomNumber.toString()
                                );
                                return (
                                  room && (
                                    <div
                                      key={room._id}
                                      className={`roomSquareUser  ${
                                        room.AC.toLowerCase() ===
                                        this.state.bookingData.RoomType.toLowerCase()
                                          ? ""
                                          : "disabled"
                                      }  
                                      ${
                                        room.status.toLowerCase() === "notclean"
                                          ? "notclean"
                                          : ""
                                      }
                                      ${
                                        busyRooms.has(room.roomNumber)
                                          ? "busy"
                                          : "available"
                                      } ${
                                        selectedRooms.includes(room.roomNumber)
                                          ? "selectedRoom animated"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        this.handleSelectSeat(room)
                                      }
                                    >
                                      {selectedRooms.includes(
                                        room.roomNumber
                                      ) && (
                                        <div className="selectedtitle">
                                          Selected
                                        </div>
                                      )}
                                      {room.roomNumber}
                                    </div>
                                  )
                                );
                              })}
                            </div>
                          </div>
                          <h6 className="text-center">First Floor</h6>
                          <div className="firstFloor">
                            <span className="label top">North</span>
                            <span className="label right">East</span>
                            <span className="label bottom">South</span>
                            <span className="label left">West</span>
                            <div className="row">
                              {[
                                120, 119, 118, 117, 116, 115, 114, 113, 112,
                                111,
                              ].map((roomNumber) => {
                                const room = guestHouseRooms.find(
                                  (room) =>
                                    room.roomNumber === roomNumber.toString()
                                );
                                return (
                                  room && (
                                    <div
                                      key={room._id}
                                      className={`roomSquareUser  ${
                                        room.AC.toLowerCase() ===
                                        this.state.bookingData.RoomType.toLowerCase()
                                          ? ""
                                          : "disabled"
                                      }  
                                      ${
                                        room.status.toLowerCase() === "notclean"
                                          ? "notclean"
                                          : ""
                                      }
                                      ${
                                        busyRooms.has(room.roomNumber)
                                          ? "busy"
                                          : "available"
                                      } ${
                                        selectedRooms.includes(room.roomNumber)
                                          ? "selectedRoom animated"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        this.handleSelectSeat(room)
                                      }
                                    >
                                      {selectedRooms.includes(
                                        room.roomNumber
                                      ) && (
                                        <div className="selectedtitle">
                                          Selected
                                        </div>
                                      )}
                                      {room.roomNumber}
                                    </div>
                                  )
                                );
                              })}
                            </div>
                            <div className="row">
                              {[
                                210, 209, 208, 207, 206, 205, 204, 203, 202,
                                201,
                              ].map((roomNumber) => {
                                const room = guestHouseRooms.find(
                                  (room) =>
                                    room.roomNumber === roomNumber.toString()
                                );
                                return (
                                  room && (
                                    <div
                                      key={room._id}
                                      className={`roomSquareUser  ${
                                        room.AC.toLowerCase() ===
                                        this.state.bookingData.RoomType.toLowerCase()
                                          ? ""
                                          : "disabled"
                                      } 
                                      ${
                                        room.status.toLowerCase() === "notclean"
                                          ? "notclean"
                                          : ""
                                      }
                                      ${
                                        busyRooms.has(room.roomNumber)
                                          ? "busy"
                                          : "available"
                                      } ${
                                        selectedRooms.includes(room.roomNumber)
                                          ? "selectedRoom animated"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        this.handleSelectSeat(room)
                                      }
                                    >
                                      {selectedRooms.includes(
                                        room.roomNumber
                                      ) && (
                                        <div className="selectedtitle">
                                          Selected
                                        </div>
                                      )}
                                      {room.roomNumber}
                                    </div>
                                  )
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              )}
            </div>
            <br />
            <p>Your Selected Room Numbers Are: {selectedRooms.join(", ")},</p>
            <p>Total Amount Is: {this.state.bookingData.TotalAmount}</p> */}
            <div>
              <p>
                <h5 className="text-center">Hare Krishna</h5> 
                <br />
                Your room number will be assigned by the admin. Please check your email for an update in <span className="yellowbg">2 Hours</span> .
                <br />
                If you do not receive an update after this time, please contact the reception directly.
                
                <p className="step4-title ">Contact details for reception:</p>
                Phone Number  : +91 9075220180
                <br />
                Email : booking@iskconpandharpur.in
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <button
                className="btn btn-danger"
                onClick={this.handleClosePopup}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={this.handlePayment}>
                Confirm & Pay
              </button>
              {this.state.isAdmin && (
                <div>
                  <button onClick={this.adminPay}>Pay Later</button>
                </div>
              )}
            </div>
          </Container>
        </div>
      );
    }
  }
}

export default Step4;
