// ApiService.js

const getToken = () => {
    return localStorage.getItem('token');
  };
  
  const setHeaders = (headers = {}) => {
    const token = getToken();
  
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  
    return headers;
  };
  
  const apiRequest = (url, options = {}) => {
    const headers = setHeaders(options.headers);
  
    return fetch(url, { ...options, headers })
      
  };
  
  export default apiRequest;
  